import { HelpCircle } from "lucide-react";
import React from "react";
import { Tooltip } from "react-tooltip";

const CustomMoffinModalTitle = (props) => {
  const { title, subtitle, withHelp = false, helpText = "" } = props;

  return (
    <>
      <div className="titleModalMoffin">
        {title}
        {withHelp && (
          <>
            <HelpCircle
              data-tooltip-id="modalMoffinHelp"
              data-tooltip-content={helpText}
              className="help-icon"
              fill={"#1A222F"}
              color="white"
            ></HelpCircle>{" "}
            <Tooltip
              id="modalMoffinHelp"
              style={{ whiteSpace: "break-spaces", backgroundColor: "#9be69f", fontWeight: 300, fontSize: "1vw", width: "14vw", padding: "1vw", color:"black" }}
              place="top"
            />
          </>
        )}
      </div>
      <div className="mb-2subtitleModal-container text-center" style={{marginBottom:"1.5vw"}}>
        <p className="text-center subtitleModalMoffin">{subtitle}</p>
      </div>
    </>
  );
};

export default CustomMoffinModalTitle;
