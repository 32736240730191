/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Request from "../../utils/fetchUtils";
import CustomLoader from "../../common/CustomLoader";
import { BadgeCheck, HelpCircle } from "lucide-react";
import { Tooltip } from "react-tooltip";
import CustomButton from "../../common/CustomButton";

const AceptarCotizacion = ({ solicitudSeleccionada, setModalAceptarCotizacion }) => {
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setQuotationInfo] = useState("");

  const fetchDataCotizacion = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    setLoading(true);
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/deal/quote?deal=" + solicitudSeleccionada.solicitudId,
      {},
      "GET",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    console.log("RES COTIZACION", res);
    if (res.status) {
      setLoading(false);
      setQuotationInfo(res.data);
    } else if (res.message === "No hay cotizaciones para mostrar") {
      setLoading(false);
      setQuotationInfo(false);
      await Swal.fire({
        icon: "error",
        title: "¡Oh no!",
        text: "No hay información sobre esta cotización.",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no algo salió mal!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const aceptarCotizacion = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    // const zohoID = localStorage.getItem("zohoContactId");
    setLoading(true);
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/deal/quote?id=" + quotationInfo.id,
      {
        Status: "Aprobado",
        Meses_aprobados: quotationInfo.Meses_aprobados,
      },
      "PATCH",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    console.log("RES ACEPTAR COTIZACION", res);
    setLoading(false);
    let timerInterval;
    if (res.ok) {
      Swal.fire({
        icon: "success",
        title: "¡Aceptó la Cotización!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(() => setModalAceptarCotizacion(false));
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no algo salió mal!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const formatearDinero = (cantidad) => {
    return `$${cantidad?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  useEffect(() => {
    fetchDataCotizacion();
  }, []);
  console.log("quotationInfo", quotationInfo);
  return (
    <div className="aceptarCotizacion-container">
      <CustomLoader isLoading={loading} />
      {quotationInfo && (
        <div className="preliminaryCard-container realQ">
          <div className="info-container realQ">
            <div className="amount-info-container" style={{ margin: 0, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <div>Plazo: {quotationInfo.Meses_aprobados} meses</div>
            </div>
            <div className="quotationBig-container">
              <div className="amount-info-container left-container">
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Comisión por apertura</div>
                  <div>{formatearDinero(quotationInfo.Comisi_n_por_apertura_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Anticipo de rentas</div>
                  <div>{formatearDinero(quotationInfo.Anticipo_de_rentas_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Renta en garantía</div>
                  <div>{formatearDinero(quotationInfo.Renta_en_garant_a_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Pago inicial sin IVA</div>
                  <div>{formatearDinero(quotationInfo.Pago_inicial_sin_IVA_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>IVA</div>
                  <div>{formatearDinero(quotationInfo.Pago_inicial_IVA_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div>Pago Inicial</div>
                  <div className="maxPayment">{formatearDinero(quotationInfo.Pago_comisi_n_por_apertura_final)}</div>
                </div>
              </div>
              <div className="amount-info-container right-container">
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Renta mensual sin IVA</div>
                  <div>{formatearDinero(quotationInfo.Renta_mensual_sin_IVA_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Accesorios</div>
                  <div>{formatearDinero(quotationInfo.Accesorios_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>Seguro</div>
                  <div>{formatearDinero(quotationInfo.Seguro_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div style={{ display: "flex", alignItems: "center" }}>IVA</div>
                  <div>{formatearDinero(quotationInfo.IVA_final)}</div>
                </div>
                <div className="info-row-amount">
                  <div>Renta Mensual Total</div>
                  <div className="maxPayment">{formatearDinero(quotationInfo.Renta_mensual_total_final)}</div>
                </div>
              </div>
            </div>
            <div className="amount-container">{formatearDinero(quotationInfo.Valor_de_compra_IVA_incluido_final)}</div>
            <div className="plain-text">Valor de compra (IVA incluido)</div>
            <div className="amount-info-container" style={{ marginTop: "1vw" }}>
              <div className="info-row-amount">
                <div>Ahorro fiscal estimado</div>
                <div className="minPayment">{formatearDinero(quotationInfo.Ahorro_fiscal_estimado_final)}</div>
              </div>
              <div className="info-row-amount" style={{ marginTop: "-1vw" }}>
                <div>Tasa real</div>
                <div className="minPayment">{quotationInfo.Tasa_real_final}%</div>
              </div>
            </div>
            {quotationInfo.Status !== "Aprobado" && (
              <div
              className="welcome-btn-container"
              style={{
                display: "flex",
                placeContent: "center",
                width: "100%",
                justifyContent: "space-between",
                padding: "0vw 2vw",
                marginBottom: "1vw",
              }}
            >
              <CustomButton
                style={{ width: "15vw" }}
                text="Cerrar"
                onClick={() => {
                  setModalAceptarCotizacion(false);
                }}
              ></CustomButton>
              <CustomButton
                style={{ width: "20vw", backgroundColor: "#73dc6f", fontWeight: 700 }}
                text="Aceptar Cotización"
                onClick={() => {
                  aceptarCotizacion();
                }}
              ></CustomButton>
            </div>
            )}
            {quotationInfo.Status === "Aprobado" && (
              <div className="plain-text"style={{fontWeight:700, margin:"1vw", display:"flex", justifyContent:"right",}}><BadgeCheck style={{marginRight:"0.5vw", color:"#73dc6f"}} width={"1.8vw"} height={"1.8vw"}></BadgeCheck>Ya aceptaste esta solicitud;)</div>
            )}
          </div>
        </div>
      )}
      {!quotationInfo && (
        <div className="preliminaryCard-container realQ">
          <div className="amount-info-container" style={{ margin: 0, flexDirection: "row", alignItems: "center", justifyContent: "center", height:"35vw" }}>
            <div style={{fontStyle:"italic", opacity:0.8}}>Sin información por mostrar</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AceptarCotizacion;
