import { HelpCircle } from "lucide-react";

const Input = ({
  label,
  withTooltip,
  tooltipText,
  icon,
  withSpecs,
  classNameContainer = "",
  className = "",
  error = "",
  ...props
}) => {
  return (
    <>
      {label && (
        <div className="input-label">
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill="#1A222F"
              color="white"
            />
          )}
        </div>
      )}
      <div
        className={`${icon ? "input-container" : "input-container2"} bg-white ${
          icon ? "" : "px-1"
        } ${classNameContainer}`}
      >
        {icon && <div className="icon-container">{icon}</div>}
        <input
          className={`input-item ${className}`}
          {...props}
          autoComplete="off"
        />
      </div>
      {error && <div className="text-red-500 text-sm -mt-2 mb-2">{error}</div>}
    </>
  );
};

export default Input;
