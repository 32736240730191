import React from "react";
import PropTypes from "prop-types";

const CustomProfileImage = (props) => {
  const { name } = props;

  const generarColorAleatorio = () => {
    const colores = [
      "#3498db",
      "#2ecc71",
      "#e74c3c",
      "#f39c12",
      "#9b59b6",
      "#34495e",
      "#1abc9c",
      "#e67e22",
    ];
    return colores[Math.floor(Math.random() * colores.length)];
  };

  const obtenerIniciales = (nombre) => {
    const palabras = nombre?.split(" ") || ["null", "null"];

    let inicialNombre = "";
    if (palabras.length > 0) {
      inicialNombre = palabras[0][0].toUpperCase();
    }

    let inicialApellido = "";
    if (palabras.length > 2) {
      inicialApellido = palabras[2][0].toUpperCase();
    } else if (palabras.length === 1) {
      inicialApellido = inicialNombre;
    } else {
      inicialApellido = palabras[1][0].toUpperCase();
    }

    return inicialNombre + inicialApellido;
  };

  const colorFondo = generarColorAleatorio();
  const iniciales = obtenerIniciales(name);

  return (
    <div
      className="profile-image-initials"
      style={{
        backgroundColor: colorFondo,
      }}
    >
      {iniciales}
    </div>
  );
};

CustomProfileImage.propTypes = {};

export default CustomProfileImage;
