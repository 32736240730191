/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import DocumentItemList from "./DocumentItemList";
import CustomModal from "../../common/CustomModal";
import CustomFileUploader from "./CustomFileUploader";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";
import CustomLoader from "../../common/CustomLoader";
import SpellingCorrection from "../../common/SpellingCorrection";
// import Typo from "typo-js";

const ItemFolderDocs = (props) => {
  const { folderData, gridView, setFolderSelected, sameAvalRL } = props;
  const [modalUploadDocs, setModalUploadDocs] = useState(false);
  const [modalUploadDocs2, setModalUploadDocs2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [estatusData, setEstatusData] = useState([]);
  const dispatch = useDispatch();

  const handleChange = () => {
    processDocs(uploadedFiles);
  };

  let firstTwoDocs;

  if (folderData?.files && folderData?.files.length > 2) {
    firstTwoDocs = folderData.files.slice(0, 2);
  }

  useEffect(() => {
    setEstatusData(folderData);
    console.log("FOLDERDATA", folderData);
  }, [folderData]);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");

    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }

  const processDocs = async (fileInput, idx) => {
    if (fileInput.length > 0) {
      var getPresignedUrlBody = new Array();
      for (let i = 0; i < fileInput.length; i++) {
        const file = fileInput[i];
        const newFile = new File(
          [fileInput[i]],
          estatusData.Name + "." + file.type.split("/")[1],
          {
            type: fileInput[i].type,
          }
        );
        const { name, type } = newFile;
        getPresignedUrlBody.push({ name, type, userFileName: file.name });
      }
      const presignedUrls = await fetchS3PresignedUrls({
        files: getPresignedUrlBody,
      });
      const PromisesPromises = presignedUrls.files.map((x, i) => {
        const { s3SignedUrl, documentName, url, folderName, s3SignedFields } =
          x;

        const file = new File([fileInput[i]], documentName, {
          type: fileInput[i].type,
        });

        const response = s3PutObjectAction(s3SignedUrl, file, s3SignedFields);

        return response;
      });

      const PromiseAll = await Promise.all(PromisesPromises);
      console.log("PROMISEALL", PromiseAll);

      const resultFailedAll = PromiseAll.filter((P) => !P.ok);
      console.log("resultFailedAll", resultFailedAll);
      let timerInterval;
      if (resultFailedAll.length === 0) {
        Swal.fire({
          icon: "success",
          title: "¡Se han subido sus documentos exitosamente!",
          text: "Revise el estatus en su dashboard para más seguimiento",
          html: "Verá reflejado sus cambios en <b></b> segundos.",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
            }, 10);
          },
        }).then(
          () => (
            setModalUploadDocs(false),
            dispatch(fetchDataFoldersZoho(setLoading))
          )
        );
      } //TODO: Modificar cuando vengan archivos corruptos, mostrar mensaje de error.
    }
  };

  const fetchS3PresignedUrls = async (files) => {
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/back-office/user/presigned-url-s3",
      files,
      "POST",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    return res;
  };

  const s3PutObjectAction = async (presignedUrl, file, s3SignedFields) => {
    try {
      const formData = new FormData();
      Object.keys(s3SignedFields).forEach((key) => {
        formData.append(key, s3SignedFields[key]);
      });
      formData.append("file", file);
      const res = await Request(presignedUrl, formData, "POST", true);

      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const resetFolder = async (folderName, expendienteID) => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      BASE_URLS.GENERAL +
        `/staging/back-office/user/folder/reset-folder?id_folder=${expendienteID}&folderName=${folderName}`,
      {},
      "GET",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (!res.ok) {
      setLoading(false);
    }
    let timerInterval;
    if (res.status) {
      Swal.fire({
        icon: "success",
        title: "¡Puede volver a subir sus documentos!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(() => setModalUploadDocs2(false));
    }
  };

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div
        className={
          gridView ? "seccionDocumentos-item" : "seccionDocumentos-item list"
        }
        onClick={
          sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;"
            ? null
            : () =>
                estatusData?.Estatus === null ||
                estatusData?.Estatus === "Pendiente"
                  ? setModalUploadDocs(true)
                  : setFolderSelected(estatusData)
        }
        style={
          sameAvalRL &&
          estatusData?.Carpeta_superior ===
            "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;"
            ? estatusData?.Estatus === null
              ? { border: "0.3vw solid #415575", opacity: 0.5 }
              : estatusData?.Estatus === "En revisión"
              ? {
                  backgroundColor: "#C8C7FE",
                  border: "0.3vw solid #4944FC",
                  opacity: 0.5,
                }
              : estatusData?.Estatus === "Aprobado"
              ? {
                  backgroundColor: "#f1fbf1",
                  border: "0.3vw solid #73dc6f",
                  opacity: 0.5,
                }
              : estatusData?.Estatus === "Rechazado"
              ? {
                  backgroundColor: "#FFFDE8",
                  border: "0.3vw solid #E9E245",
                  opacity: 0.5,
                }
              : { border: "0.3vw solid #415575", opacity: 0.5 }
            : estatusData?.Estatus === null
            ? { border: "0.3vw solid #415575" }
            : estatusData?.Estatus === "En revisión"
            ? { backgroundColor: "#C8C7FE", border: "0.3vw solid #4944FC" }
            : estatusData?.Estatus === "Aprobado"
            ? { backgroundColor: "#f1fbf1", border: "0.3vw solid #73dc6f" }
            : estatusData?.Estatus === "Rechazado"
            ? { backgroundColor: "#FFFDE8", border: "0.3vw solid #E9E245" }
            : { border: "0.3vw solid #415575" }
        }
      >
        <div
          style={
            gridView
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }
          }
        >
          <div className={gridView ? "item-icon-grid" : "item-icon-grid list"}>
            {estatusData?.Estatus === "Pendiente" && (
              <img
                className="iconItem"
                src="./images/nuevoIcon.svg"
                alt=""
              ></img>
            )}
            {estatusData?.Estatus === "En revisión" && (
              <img
                className="iconItem"
                src="./images/esperaIcon.svg"
                alt=""
              ></img>
            )}
            {estatusData?.Estatus === "Aprobado" && (
              <img
                className="iconItem"
                src="./images/aceptadoIcon.svg"
                alt=""
              ></img>
            )}
            {estatusData?.Estatus === "Rechazado" && (
              <img
                className="iconItem"
                src="./images/rechazadoIcon.svg"
                alt=""
              ></img>
            )}
          </div>
          <div
            className={gridView ? "item-title" : "item-title list"}
            style={
              estatusData?.Estatus === "Pendiente" ||
              estatusData?.Estatus === "Rechazado"
                ? { color: "#2D3B52" }
                : {}
            }
          >
            {
              <SpellingCorrection
                texto={formatearNombre(folderData.Name)}
              ></SpellingCorrection>
            }
          </div>
          {gridView && (
            <>
              {sameAvalRL &&
              estatusData?.Carpeta_superior ===
                "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
                <div
                  className="docList-title"
                  style={{
                    textAlign: "center",
                    marginTop: "1vw",
                    fontSize: "1.1vw",
                    fontStyle: "italic",
                  }}
                >
                  Documentos iguales al Representante Legal
                </div>
              ) : (
                <div className="item-docList">
                  {estatusData?.Estatus === "Pendiente" && (
                    <div
                      className="docList-title"
                      style={{
                        textAlign: "center",
                        fontSize: "1.1vw",
                        marginTop: "1vw",
                        fontStyle: "italic",
                      }}
                    >
                      Haga clic aquí para subir sus archivos...
                    </div>
                  )}
                  {estatusData?.Estatus !== "Pendiente" && (
                    <>
                      <div className="docList-title">
                        Mis documentos ({estatusData?.files?.length})
                      </div>
                      {estatusData?.files &&
                        estatusData?.files?.length > 2 &&
                        firstTwoDocs.map((x) => (
                          <DocumentItemList
                            docsList={x}
                            key={x.id}
                            docsStatus={estatusData?.Estatus}
                          ></DocumentItemList>
                        ))}
                      {!(estatusData?.files && estatusData.files.length > 2) &&
                        estatusData?.files?.map((x) => (
                          <DocumentItemList
                            docsList={x}
                            key={x.id}
                            docsStatus={estatusData?.Estatus}
                          ></DocumentItemList>
                        ))}
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {!gridView && (
            <>
              {sameAvalRL &&
              estatusData?.Carpeta_superior ===
                "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
                <div
                  className="docList-title"
                  style={{ fontSize: "1.1vw", fontStyle: "italic" }}
                >
                  Documentos iguales al Representante Legal
                </div>
              ) : (
                <div className="item-docList" style={{ width: "50%" }}>
                  {estatusData?.Estatus === "Pendiente" && (
                    <div
                      className="docList-title"
                      style={{ textAlign: "center", fontSize: "1.2vw" }}
                    >
                      Haga clic aquí para subir sus archivos...
                    </div>
                  )}
                  {estatusData?.Estatus !== "Pendiente" && (
                    <div
                      className="docList-title"
                      style={{ textAlign: "center" }}
                    >
                      Mis documentos ({estatusData?.files?.length})
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {gridView && estatusData?.files?.length > 2 && (
          <div
            className="item-action"
            onClick={() => setModalUploadDocs2(true)}
          >
            {estatusData?.Estatus === "Rechazado"
              ? "Ver motivo de rechazo"
              : "Ver todos los documentos"}
          </div>
        )}
        {!gridView && estatusData?.files?.length >= 1 && (
          <div
            className="item-action list"
            onClick={() => setModalUploadDocs2(true)}
          >
            {estatusData?.Estatus === "Rechazado"
              ? "Ver motivo de rechazo"
              : "Ver todos los documentos"}
          </div>
        )}
      </div>
      <CustomModal
        open={modalUploadDocs}
        setOpen={setModalUploadDocs}
        title={"Subir documentos"}
        withBtnsFooter={false}
        strongTitle={true}
        extraText={
          "Si guardas los cambios tu archivo se subirá y pasará a revisión"
        }
      >
        <div className="modal-container">
          <div className="nombreCarpeta-modal">
            {
              <SpellingCorrection
                texto={formatearNombre(folderData.Name)}
              ></SpellingCorrection>
            }
          </div>
          <CustomFileUploader
            handleChange={handleChange}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
          ></CustomFileUploader>
        </div>
      </CustomModal>
      <CustomModal
        open={modalUploadDocs2}
        setOpen={setModalUploadDocs2}
        title={"Mis Documentos"}
        withBtnsFooter={false}
        strongTitle={true}
      >
        <div className="modal-container">
          <div className="nombreCarpeta-modal">
            {
              <SpellingCorrection
                texto={formatearNombre(folderData.Name)}
              ></SpellingCorrection>
            }
          </div>
          <div
            style={{
              width: "100%",
              fontSize: "1vw",
              fontFamily: "Guaruja Neue Regular",
            }}
          >
            Total de documentos:{" "}
            <span style={{ color: "#9be69f", fontSize: "1.5vw" }}>
              {estatusData?.files?.length}
            </span>
          </div>
          <div className="seccionDocumentos-item list modal">
            {estatusData?.files &&
              estatusData?.files?.map((x) => (
                <DocumentItemList
                  docsList={x}
                  key={x.id}
                  docsStatus={estatusData?.Estatus}
                ></DocumentItemList>
              ))}
          </div>
          {estatusData?.Estatus === "Rechazado" && (
            <>
              <div
                style={{
                  width: "100%",
                  fontSize: "1vw",
                  fontFamily: "Guaruja Neue Regular",
                }}
              >
                Motivo del rechazo:
              </div>
              <div className="seccionDocumentos-item list modal">
                <div
                  style={{
                    color: "black",
                    width: "100%",
                    fontFamily: "Guaruja Neue Regular",
                    fontStyle: "italic",
                    maxHeight: "12vw",
                    overflow: "auto",
                  }}
                >
                  {estatusData?.Comentarios}
                </div>
              </div>
              <div
                className="btnVolerPendiente"
                onClick={() =>
                  resetFolder(estatusData.Name, estatusData.expedienteId)
                }
              >
                Acepto el motivo y deseo intentarlo otra vez
              </div>
            </>
          )}
        </div>
      </CustomModal>
    </>
  );
};

ItemFolderDocs.propTypes = {};

export default ItemFolderDocs;
