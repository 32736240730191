import { useSession } from "../../utils/sessionUtils";
import { Navigate } from "react-router-dom";

const MainLayout = (props) => {
  const { valid } = useSession();

  if (!valid) return <Navigate to="/" replace />;

  return <>{props.children}</>;
};

export default MainLayout;
