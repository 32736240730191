import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

const ProgressDocBar = (props) => {
  const { catName, percent, arrayLength } = props;
  const he = require("he");

  const getArrayPercent = () => {
    if (arrayLength === 0) {
      return 0;
    }
    return (percent / arrayLength) * 100;
  };
  return (
    <div style={{ marginBottom: "0.8vw" }}>
      <div className="nameProgressBar">{he.decode(catName)}</div>
      <ProgressBar
        completed={getArrayPercent()}
        height={"1vw"}
        bgColor={catName.includes("express") ? "#4944FC" : catName === "Representante legal" ? "#A3A2FE" : "#73DC6F"}
        borderRadius={"1vw"}
        isLabelVisible={true}
      />
    </div>
  );
};

export default ProgressDocBar;
