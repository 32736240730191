//import Swal from "sweetalert2";
import { endSession } from "./sessionUtils";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

export const BASE_URLS = {
  GENERAL: "https://f101pahekh.execute-api.us-east-2.amazonaws.com",
};

const Request = async (
  path,
  body = {},
  method = "GET",
  isFormdata = false,
  isResponseBlob = false,
  headersProp
) => {
  const headers = headersProp || {
    //Accept: "application/json",
    // Authorization: getFromSession("jwt"),
    ...(isFormdata
      ? {}
      : {
          "Content-Type": "application/json",
        }),
    //...(validateSession() ? { Authorization: getFromSession("jwt") } : {}),
  };
  let response = { ok: false };
  try {
    const reqResponse = await fetch(path, {
      method,
      headers: headers,
      body: ["GET", "DELETE"].includes(method)
        ? null
        : isFormdata
        ? body
        : JSON.stringify(body),
    });

    if (
      (reqResponse.status === 204 || reqResponse.status === 200) &&
      method === "PATCH"
    )
      return { ok: true };

    response = isResponseBlob
      ? await reqResponse.blob()
      : await reqResponse?.json();

    return response;
    // console.log("ReqResponse", reqResponse);
    // if (reqResponse.ok) {
    // if (reqResponse.status !== 204) {
    //   response = isResponseBlob
    //     ? await reqResponse.blob()
    //     : (await reqResponse?.json()) || {};
    // } else {
    //   response = { ok: true };
    // }
    // }
    /**
     *  else {
      const errResponse = (await reqResponse?.clone()) || {};
      switch (reqResponse.status) {
        case 400:
          Swal.fire({
            title: "Ha ocurrido un error",
            text: errResponse.message || "Inténtelo de nuevo",
            icon: "error",
          });
          break;
        case 401:
          Swal.fire({
            title: "Ops",
            text: "La sesión ha expirado, debes iniciar sesión nuevamente",
            icon: "warning",
          }).then(() => {
            endSession();
          });
          break;
        case 500:
          Swal.fire({
            title: "Ops",
            text: errResponse.message,
            icon: "error",
          });
          break;
        default:
          // Swal.fire({
          //   title: "Ocurrió un error",
          //   text: errResponse.error || errResponse.msg,
          //   icon: "error",
          // });
          break;
      }
    }
     */
  } catch (error) {
    console.log(error);
    // Swal.fire({
    //   icon: "error",
    //   title: "Ocurrió un error desconocido",
    //   text: error,
    // });
  }
  return response;
};

Request.propTypes = {
  path: PropTypes.string.isRequired,
  body: PropTypes.object,
  method: PropTypes.oneOf(["GET", "POST", "PUT", "PATCH", "DELETE"]),
  isFormdata: PropTypes.bool,
  isResponseBlob: PropTypes.bool,
};

export default Request;
