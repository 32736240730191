import React from "react";
import { ChevronsRight } from "lucide-react";

const CustomButton = (props) => {
  const {
    text,
    onClick,
    style,
    disabled = false,
    icon = true,
    className = "",
  } = props;
  return (
    <div
      className="btn-container"
      onClick={!disabled ? onClick : () => {}}
      style={style}
    >
      {icon && (
        <div className="icon-container">
          <ChevronsRight />
        </div>
      )}
      <div className={`textBtn-container ${className}`}>{text}</div>
    </div>
  );
};

export default CustomButton;
