export const endSession = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/";
};

export const getFromSession = (key) => {
  return localStorage.getItem(key) || sessionStorage.getItem(key);
};

export const validateSession = () => {
  return getFromSession("jwt") !== null || getFromSession("sUserData") !== null;
};

export const useSession = () => {
  const valid =
    getFromSession("jwt") !== null && getFromSession("sUserData") !== null;

  return { valid };
};
