/* eslint-disable no-unused-vars */
import { Dialog, Transition } from "@headlessui/react";
import { CircleX, X } from "lucide-react";
import { Fragment, React, useRef } from "react";
import CustomButton from "./CustomButton";
import CustomButton2 from "./CustomButton2";

const CustomModal = (props) => {
  const {
    open,
    setOpen,
    children,
    title,
    extraText,
    confirmButtonText = "Guardar Cambios",
    maxWidth = "60vw",
    maxHeight = "37vw",
    strongTitle = false,
    withBtnsFooter = true,
    className,
    fromMoffin = false,
    withCloseTag = false,
    action = () => {},
  } = props;

  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment} className={className}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 modalContainer">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg modalGeneral-container"
                style={{
                  maxWidth: maxWidth,
                  padding: "1.5vw",
                  backgroundColor: "#1a222f",
                }}
              >
                <div
                  className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bodyGModal"
                  style={
                    fromMoffin ? { height: "42vw" } : { height: maxHeight }
                  }
                >
                  <div
                    className="mt-3 text-center sm:mt-0 sm:text-left"
                    style={
                      fromMoffin
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }
                        : {}
                    }
                  >
                    {!fromMoffin && (
                      <>
                        <Dialog.Title
                          as="h3"
                          className="text-lg text-center font-medium leading-6 titleModal"
                          style={
                            strongTitle
                              ? {
                                  fontFamily: "filson_proregular",
                                  fontSize: "2.5vw",
                                  lineHeight: "2.5vw",
                                  padding: "0vw 1vw",
                                }
                              : { fontSize: "1.5vw" }
                          }
                        >
                          {title}
                          {withCloseTag && (
                            <CircleX
                              className="closeTagModal"
                              onClick={() => setOpen(false)}
                              title="Cerrar Modal"
                            />
                          )}
                        </Dialog.Title>
                        <div className="mb-2 p-2 subtitleModal-container text-center">
                          <p className="text-center">{extraText}</p>
                        </div>
                      </>
                    )}

                    {children}
                  </div>
                </div>
                {withBtnsFooter && (
                  <div
                    className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 btnsFooter"
                    style={{ justifyContent: "space-between" }}
                  >
                    <CustomButton
                      text="Guardar"
                      style={{ backgroundColor: "white", width: "15vw" }}
                      onClick={() => {
                        action();
                        // setOpen(false);
                      }}
                    ></CustomButton>
                    <CustomButton2
                      text="Cancelar"
                      ref={cancelButtonRef}
                      icon={<X></X>}
                      style={{
                        borderColor: "white",
                        color: "white",
                        width: "10vw",
                      }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    ></CustomButton2>
                    {/* <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancelar
                    </button> */}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

CustomModal.propTypes = {};

export default CustomModal;
