import { useState } from "react";

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }) => {

    if(target.name === "Valor_del_equipo"){
      let value = target.value;
      value = value.replace(/[^0-9.]/g, '');
      const parts = value.split('.');
      let integerPart = parts[0];
      let decimalPart = parts[1] || '';
      decimalPart = decimalPart.slice(0, 2);
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setValues({
        ...values,
        [target.name]: "$"+integerPart + (decimalPart ? '.' + decimalPart : ''),
      });
    } else if (target.name === "Fecha_de_nacimiento") {
      
      let formattedDateForUser = target.value.replace(/\D/g, "");
      if (formattedDateForUser.length > 2) {
        formattedDateForUser = formattedDateForUser.slice(0, 2) + '/' + formattedDateForUser.slice(2);
      }
      if (formattedDateForUser.length > 5) {
        formattedDateForUser = formattedDateForUser.slice(0, 5) + '/' + formattedDateForUser.slice(5);
      }
      if (formattedDateForUser.length > 10) {
        formattedDateForUser = formattedDateForUser.slice(0, 10);
      }
      setValues({
        ...values,
        [target.name]: formattedDateForUser
      });
      
    }else {
      setValues({
        ...values,
        [target.name]: target.value,
      });
    }    
  };

  const formatForUser = (date) => {
    if (!date) return '';
  
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
  
    return `${year}/${month}/${day}`;
  };

  const setAllValues = (valuesState) => {
    setValues(valuesState);
  };

  return [values, handleInputChange, reset, setAllValues];
};
