import React, { useState } from "react";
import { ChevronsLeft } from "lucide-react";
import CustomButton from "../../../common/CustomButton";
import CustomCodeInput from "../../../common/CustomCodeInput";
import Request, { BASE_URLS } from "../../../utils/fetchUtils";
import Swal from "sweetalert2";
import { getFromSession } from "../../../utils/sessionUtils";
import { useNavigate } from "react-router-dom";

const ViewVerifyCode = (props) => {
  const { slider, setSlider, setLoading } = props;
  const [codigo, setCodigo] = useState("");
  const navigate = useNavigate();

  const actualizarCodigo = (nuevoCodigo) => {
    setCodigo(nuevoCodigo);
  };

  const verifyEmail = async () => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/verify-email",
      {
        email: getFromSession("emailGiven"),
        confirmationCode: codigo,
      },
      "POST"
    );
    if (res.status) {
      Swal.fire({
        icon: "success",
        title: "¡Su correo ha sido verificado exitosamente!",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => navigate("/auth/login"));
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setLoading(false);
  };

  return (
    <div className="welcome-container" slider={slider}>
      <div className="info-container">
        <div className="header-container">
          <div
            className="back-container"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <ChevronsLeft className="backButton"></ChevronsLeft>
          </div>
          <div className="image-container">
            <img
              className="iconView"
              src="/images/codeIcon.svg"
              alt="code icon"
            ></img>
          </div>
        </div>
        <div className="title-container">
          <span>Ingresa tu código</span>
        </div>
        <div className="info-text-container">
          Te enviamos un código a tu email, introdúcelo a continuación.
        </div>
        <div className="verifyCode-container">
          <CustomCodeInput
            actualizarCodigo={actualizarCodigo}
          ></CustomCodeInput>
          {/* <div className="resendCode-container">Reenviar código</div> */}
        </div>
        <div className="welcome-btn-container">
          <CustomButton
            text="Continuar"
            onClick={() => {
              verifyEmail();
              setSlider(1);
            }}
          ></CustomButton>
        </div>
      </div>
      <div className="btnAction-container">
        <div className="btn">
          <img className="btnNext" src="/images/nextBtn.svg"></img>
        </div>
      </div>
    </div>
  );
};

ViewVerifyCode.propTypes = {};

export default ViewVerifyCode;
