import React from "react";
import PropTypes from "prop-types";
import Menu from "./Menu";

const Skinpage = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#2D3B52" }}>
      <div style={{ display: "flex" }}>
        <Menu></Menu>
        <div className="skinpage-container">{children}</div>
      </div>
    </div>
  );
};

Skinpage.propTypes = {
  children: PropTypes.element,
};

export default Skinpage;
