import React, { useState } from "react";

import ViewVerifyCode from "../../modules/Onboarding/Views/ViewVerifyCode";
import ViewForgotPassword from "../../modules/Onboarding/Views/ViewForgotPassword";
import ViewForgotPassword2 from "../../modules/Onboarding/Views/ViewForgotPassword2";
import ViewForgotPassword3 from "../../modules/Onboarding/Views/ViewForgotPassword3";
import CustomLoader from "../../common/CustomLoader";
import ViewVerifyCodePass from "../../modules/Onboarding/Views/ViewVerifyCodePass";

const Forgot = (props) => {
  const [section, setSection] = useState("ForgotPass1");
  const [loading, setLoading] = useState(false);
  const [slider, setSlider] = useState(0);

  const mostrarSeccion = (sectionName) => {
    switch (sectionName) {
      case "VerifyCode":
        return (
          <ViewVerifyCode
            setSection={setSection}
            slider={slider}
            setSlider={setSlider}
            setLoading={setLoading}
          ></ViewVerifyCode>
        );
      case "ForgotPass1":
        return (
          <ViewForgotPassword
            setSection={setSection}
            slider={slider}
            setSlider={setSlider}
            setLoading={setLoading}
          ></ViewForgotPassword>
        );
      case "VerifyCodePass":
        return (
          <ViewVerifyCodePass
            setSection={setSection}
            slider={slider}
            setSlider={setSlider}
            setLoading={setLoading}
          ></ViewVerifyCodePass>
        );
      case "ForgotPass2":
        return (
          <ViewForgotPassword2
            setSection={setSection}
            slider={slider}
            setSlider={setSlider}
            setLoading={setLoading}
          ></ViewForgotPassword2>
        );
      case "ForgotPass3":
        return (
          <ViewForgotPassword3
            setSection={setSection}
            slider={slider}
            setSlider={setSlider}
            setLoading={setLoading}
          ></ViewForgotPassword3>
        );
      default:
        return <ViewForgotPassword></ViewForgotPassword>;
    }
  };

  return (
    <>
      <div className="general-container">
        <div className="session-container"></div>
        <div className="footer-container">
          <img
            className="selloAnimado"
            src="/SelloAnimado.svg"
            alt="animado"
          ></img>
        </div>
      </div>
      {mostrarSeccion(section)}
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default Forgot;
