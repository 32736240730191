import { ChevronsLeft, KeyRound, KeySquare } from "lucide-react";
import React, { useState } from "react";
import Swal from "sweetalert2";
import CustomButton from "../../../common/CustomButton";
import Request, { BASE_URLS } from "../../../utils/fetchUtils";
import { getFromSession } from "../../../utils/sessionUtils";
import { isPasswordSecure } from "../../../utils";
import { InputPassword, PasswordMinRequired } from "../../../components";
import { useNavigate } from "react-router-dom";

const ViewForgotPassword2 = (props) => {
  const { setSection, slider, setSlider, setLoading } = props;
  const [password, setPassword] = useState("");
  const [confirmarContraseña, setConfirmarContraseña] = useState("");
  const [contraseñasCoinciden, setContraseñasCoinciden] = useState(true);
  const [cumpleRequisitos, setCumpleRequisitos] = useState(true);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordConfirm") {
      setConfirmarContraseña(value);
    }

    // Validaciones adicionales
    const isStrong = isPasswordSecure(value);
    setCumpleRequisitos(isStrong);

    // Compara las contraseñas y actualiza el estado de contraseñasCoinciden
    setContraseñasCoinciden(value === password);
  };

  const resetPassword = async () => {
    setLoading(true);
    if (contraseñasCoinciden && cumpleRequisitos) {
      const res = await Request(
        BASE_URLS.GENERAL + "/staging/auth/reset-password",
        {
          email: getFromSession("emailGiven"),
          password: password,
          confirmationCode: getFromSession("verifyCode"),
        },
        "POST"
      );
      if (res.status) {
        setLoading(false);
        setSection("ForgotPass3");
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "¡Oh no algo salió mal! Inténtalo de nuevo",
          showConfirmButton: true,
        });
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Hay un problema con tu contraseña",
        text: "Revisa que tus contraseñas coincidan y/o que cumplan con los requisitos.",
        showConfirmButton: true,
      });
    }
  };

  return (
    <div className="welcome-container" slider={slider}>
      <div className="info-container">
        <div className="header-container">
          <div
            className="back-container"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <ChevronsLeft className="backButton"></ChevronsLeft>
          </div>
          <div className="image-container">
            <img
              className="iconView"
              alt="icon view"
              src="/images/forgotPass2Icon.svg"
            ></img>
          </div>
        </div>
        <div className="title-container-register">
          <span>Ingresa una nueva contraseña</span>
        </div>
        <div className="info-text-container">
          Ingresa una nueva contraseña y guárdala en un lugar seguro.
        </div>
        <div className="form-container-register">
          <InputPassword
            placeholder="Contraseña"
            name="password"
            type="password"
            label="Crea tu contraseña"
            value={password}
            styles={{
              border: contraseñasCoinciden
                ? "2px solid green"
                : "2px solid red",
            }}
            onChange={handleInputChange}
            icon={<KeyRound></KeyRound>}
          />
          <InputPassword
            placeholder="Confirmar Contraseña"
            name="passwordConfirm"
            type="password"
            label="Confirma tu contraseña"
            value={confirmarContraseña}
            withSpecs
            styles={{
              border: contraseñasCoinciden
                ? "2px solid green"
                : "2px solid red",
            }}
            onChange={handleInputChange}
            icon={<KeySquare></KeySquare>}
          />
          {!contraseñasCoinciden &&
            password.length > 0 &&
            confirmarContraseña.length > 0 && (
              <p style={{ color: "red" }} className="text-sm mb-1">
                Las contraseñas no coinciden.
              </p>
            )}
          <PasswordMinRequired value={password} />
        </div>
        <div className="welcome-btn-container">
          <CustomButton
            text="Continuar"
            onClick={() => {
              resetPassword();
            }}
          ></CustomButton>
        </div>
      </div>
      <div className="btnAction-container">
        <div
          className="btn"
          onClick={() => {
            resetPassword();
          }}
        >
          <img
            className="btnNext"
            alt="next button"
            src="/images/nextBtn.svg"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default ViewForgotPassword2;
