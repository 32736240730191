import { CheckCircle2 } from "lucide-react";
import { typeValidationPassword } from "../../utils";

const PasswordMinRequired = ({ value }) => {
  const validator = (text = "", regex = /^$/) => regex.test(text);

  return (
    <div className="flex flex-col justify-evenly">
      {typeValidationPassword.map((vl) => (
        <div className="inputSpec" key={vl.id}>
          <CheckCircle2
            className={`${
              validator(value, vl.regex) ? "text-emerald-300" : "text-red-300"
            }`}
          ></CheckCircle2>
          <span>{vl.name}</span>
        </div>
      ))}
    </div>
  );
};

export default PasswordMinRequired;
