import React from "react";
import { FilePlus } from "lucide-react";

const CustomTable = (props) => {
  const { headers, data, fromHistorial = false } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  console.log("DATaSOLICITUDES", data);
  return (
    <div className="table-container">
      <div className="headers-container">
        <div className="item-header icon-header"></div>
        {headers.map((x, i) => (
          <div key={i} className="item-header" style={{ width: x.headerSize }}>
            {x.headerName}
          </div>
        ))}
        <div className="item-header actions-header">Acciones</div>
      </div>
      <div className="rows-container" style={fromHistorial ? { maxHeight: "39vw" } : {}}>
        {data?.map((row, index) => (
          <div key={index + 1} className="item-row">
            <div className="cell icon-cell">
              <FilePlus />
            </div>
            {headers.map((header, idx) => (
              <div key={idx + 1} className="cell" style={{ width: header.headerSize }}>
                {header.field === "createdAt" ? formatDate(row[header.field]) : row[header.field]}
              </div>
            ))}
            {row.acciones && (
              <div className="cell actions-cell">
                {row.acciones.map((action, idx) => (
                  <>
                    {action && (
                      <button key={idx + 1} onClick={action.accion} title={action.nombre}>
                        {action.icon}
                      </button>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomTable;
