import CryptoJS from "crypto-js";

export const isPasswordSecure = (password = "") => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]{8,}$/;
  return regex.test(password);
};

export const typeValidationPassword = [
  {
    id: "lower-and-upper-case",
    name: "1 Mayúscula y minúscula",
    regex: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
  },
  {
    id: "number",
    name: "1 Número",
    regex: /^(?=.*\d).+$/,
  },
  {
    id: "special-character",
    name: "1 Carácter especial",
    regex: /^(?=.*[!@#$%^&*()_+\-=\[\]{}|;':",.<>\/?]).+$/,
  },
  {
    id: "caracters",
    name: "Más de 8 carácteres",
    regex: /^.{8,}$/,
  },
];

export const isValidRFC = (rfc = "", moral = false) => {
  if (moral)
    return /^([A-ZÑ&]{3})(\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))([A-Z\d]{2}[A\d])$/.test(
      rfc
    );

  return /^([A-ZÑ&]{4})(\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))([A-Z\d]{3})$/.test(
    rfc
  );
};

export const convertStringValid = {
  Deal_Name: "del nombre completo",
  Telefono: "del teléfono",
  Situacion_fiscal: "de la situación Fiscal",
  email: "del correo electronico",
  Plazo_de_interes: "de los plazos",
  Tipo_de_servicio: "del tipo de servicio",
  tipo_de_industria: "del tipo de activo",
  Valor_del_equipo: "del valor del equipo",
  RFC: "del RFC",
  Ubicacion: "de la ubicación",
  An_os_operando: "de los años operando",
  Giro_de_negocio: "del giro de negocio",
  Facturacion_anual: "de la factura anual promedio",
  Estado_en_buro: "de la consideración que está el buró de tu empresa",
  Razon_Social: "de la razón social",
};

export const moralRequired = ["Razon_Social"];
export const requiredSteps = {
  step1: [
    "Deal_Name",
    "Telefono",
    "email",
    "password",
    "Tipo_de_servicio",
    "tipo_de_industria",
    "Valor_del_equipo",
    "Plazo_de_interes",
  ],
  step2: [
    "Situacion_fiscal",
    "Ubicacion",
    "Giro_de_negocio",
    "An_os_operando",
    "RFC",
    "Facturacion_anual",
    "Estado_en_buro",
  ],
  step3: [],
};

const secretKey = "Kcq+aT+r0IO/3/4Q";

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};
