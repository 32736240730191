import { ChevronsLeft } from "lucide-react";
import React, { useState } from "react";
import CustomButton from "../../../common/CustomButton";
import CustomCodeInput from "../../../common/CustomCodeInput";

const ViewVerifyCodePass = (props) => {
  const { setSection, slider, setSlider, setLoading } = props;
  const [codigo, setCodigo] = useState("");

  const actualizarCodigo = (nuevoCodigo) => {
    setCodigo(nuevoCodigo);
  };

  const recoverCode = async () => {
    setLoading(true);
    localStorage.setItem("verifyCode", codigo);
    setSection("ForgotPass2");
    setLoading(false);
  };

  return (
    <div className="welcome-container" slider={slider}>
      <div className="info-container">
        <div className="header-container">
          <div
            className="back-container"
            onClick={() => {
              setSection("Welcome");
              setSlider(0);
            }}
          >
            <ChevronsLeft className="backButton"></ChevronsLeft>
          </div>
          <div className="image-container">
            <img className="iconView" src="/images/codeIcon.svg"></img>
          </div>
        </div>
        <div className="title-container">
          <span>Ingresa tu código</span>
        </div>
        <div className="info-text-container">
          Te enviamos un código a tu email, introdúcelo a continuación.
        </div>
        <div className="verifyCode-container">
          <CustomCodeInput
            actualizarCodigo={actualizarCodigo}
          ></CustomCodeInput>
          {/* <div className="resendCode-container">Reenviar código</div> */}
        </div>
        <div className="welcome-btn-container">
          <CustomButton
            text="Continuar"
            onClick={() => {
              recoverCode();
            }}
          ></CustomButton>
        </div>
      </div>
      <div className="btnAction-container">
        <div
          className="btn"
          onClick={() => {
            recoverCode();
          }}
        >
          <img className="btnNext" src="/images/nextBtn.svg"></img>
        </div>
      </div>
    </div>
  );
};

ViewVerifyCodePass.propTypes = {};

export default ViewVerifyCodePass;
