import { GENERAL_INIT, GENERAL_LOGOUT} from ".";
import Request from "../../utils/fetchUtils";
import { endSession } from "../../utils/sessionUtils";

let interval;

export const fetchDataFoldersZoho = (setLoading, setArreglosAgrupados) => async (dispatch, getState) => {
  const tokenJwt = localStorage.getItem("jwt")
  setLoading(true);
  const res = await Request(
    `https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact/expediente`,
    {},
    "GET",
    false,
    false,
    {
      Authorization: `Bearer ${tokenJwt}`
    },
  );
  console.log("ENTRE AL REDUX Y MI RES ES: ", res);
  setLoading(false);
  if (res.status) {
    localStorage.setItem("zohoAttributes", JSON.stringify(res.expedientes));
    dispatch({type: GENERAL_INIT, payload: JSON.stringify(res.expedientes)});
    if(setArreglosAgrupados){
      const arreglosAgrupados = agruparPorCarpetaSuperior(res.expedientes);
      setArreglosAgrupados(arreglosAgrupados);
    }
  
  }
  // else {
  //   Swal.fire({
  //     icon: "error",
  //     title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
  //     showConfirmButton: false,
  //     timer: 2000,
  //   });
  // }
};

const agruparPorCarpetaSuperior = (objetos) => {
  const grupos = objetos.reduce((acumulador, objeto) => {
    const grupoExistente = acumulador.find((arr) => arr[0]?.Carpeta_superior === objeto.Carpeta_superior);
    if (grupoExistente) {
      grupoExistente.push(objeto);
    } else {
      acumulador.push([objeto]);
    }
    return acumulador;
  }, []);

  // Ordenar los grupos
  grupos.sort((grupoA, grupoB) => {
    // Función para obtener la posición de un grupo cuyo primer elemento tiene "documentacion_expres"
    const getOrden = (grupo) => grupo[0]?.Carpeta_superior === 'Documentaci&oacute;n Express' ? -1 : 1;

    // Comparar los grupos basados en su orden
    return getOrden(grupoA) - getOrden(grupoB);
  });

  return grupos;
};


// export const initialFetch = (userData) => async (dispatch, getState) => {
//   const _Request = Request(
//     BASE_URLS.NOTIFICATIONS +
//       "/notification/" +
//       userData.userID +
//       "?from=" +
//       parseObjectDate(addDays(TODAY, -3), 4)
//   );
//   interval = setInterval(async () => {
//     const res = await _Request;
//     if (res.status === "success") {
//       dispatch({ type: GENERAL_NOTIFICATIONS, payload: res.data });
//     }
//   }, 60000);
//   const res = await _Request;
//   if (res.status === "success") {
//     dispatch({ type: GENERAL_NOTIFICATIONS, payload: res.data });
//   }
//   dispatch({ type: GENERAL_INIT, payload: userData });
// };

export const startLogout = () => (dispatch) => {
  dispatch({ type: GENERAL_LOGOUT });
  interval = null;
  endSession();
};
