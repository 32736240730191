import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CustomLoader from "../../common/CustomLoader";
import CustomModal from "../../common/CustomModal";
import SpellingCorrection from "../../common/SpellingCorrection";
import { encryptData, isValidRFC } from "../../utils";
import { axiosInstance } from "../../utils/fetch-v2";
import Input from "../forms/input";
import InputPassword from "../forms/input-password";

const EkatenaRequest = ({ folderData, gridView }) => {
  function formatearNombre(nombre) {
    const palabras = nombre.split("_");

    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }
  const { userData } = useSelector((state) => state.general);

  const [createReport, setCreateReport] = useState(null);
  const [checked, setChecked] = useState(
    localStorage.getItem("avalMismoRL") === "Si" ? true : false
  );
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isValidRfc, setIsValidRfc] = useState(false);
  const [error, setError] = useState({ text: "", type: null });
  const [values, setValues] = useState({
    ciec: "",
    rfc: "",
  });

  const loadDataMe = async () => {
    setLoading(true);
    axiosInstance
      .get("/user/me")
      .then((res) => {
        if (res.data) {
          setValues({ ...values, rfc: res.data?.RFC });
          setLoading(false);
          let status = "ERROR";

          if (!res.data?.reportEkatena) status = "INITIAL";

          if (res.data?.reportEkatena?.pdfReportUrl) status = "SUCCESS";
          if (
            !res.data?.reportEkatena?.pdfReportUrl &&
            res.data?.reportEkatena?.id
          )
            status = "PENDING";

          if (res.data?.reportEkatena?.ciedStatus === "INVALID")
            status = "INVALID";

          setCreateReport(status);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("e", e);
      });
  };

  useEffect(() => {
    loadDataMe();
  }, []);

  const onSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const encryptedData = encryptData({
        ciec: values.ciec,
        rfc: values.rfc,
      });

      const resp = await axiosInstance.post(
        "/admin/ekatena-report",
        encryptedData
      );
      if (resp?.data?.id) {
        setLoading(false);
        setOpenModal(false);
        Swal.fire({
          icon: "success",
          title: "¡Éxito!",
          text: "Se ha generado el reporte con éxito",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            setOpenModal(false);
          },
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const newDataChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div
        className={
          gridView
            ? "seccionDocumentos-item"
            : "seccionDocumentos-item list items-center bg-red-200"
        }
        style={
          createReport === "SUCCESS"
            ? { backgroundColor: "#f1fbf1", border: "0.3vw solid #73dc6f" }
            : createReport === "PENDING"
            ? {
                backgroundColor: "#C8C7FE",
                border: "0.3vw solid #4944FC",
              }
            : createReport === "INVALID"
            ? { backgroundColor: "#fecaca", border: "0.3vw solid red" }
            : { border: "0.3vw solid #415575" }
        }
        onClick={() =>
          (createReport === "INITIAL" || createReport === "INVALID") &&
          setOpenModal(true)
        }
      >
        <div
          style={
            gridView
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }
          }
        >
          <div className={gridView ? "item-icon-grid" : "item-icon-grid list"}>
            {createReport === "PENDING" ? (
              <img
                className="iconItem"
                src="./images/esperaIcon.svg"
                alt=""
              ></img>
            ) : createReport === "SUCCESS" ? (
              <img
                className="iconItem"
                src="./images/aceptadoIcon.svg"
                alt=""
              ></img>
            ) : createReport === "INVALID" || createReport === "INITIAL" ? (
              <img
                className="iconItem"
                src="./images/nuevoIcon.svg"
                alt=""
              ></img>
            ) : (
              <img
                className="iconItem"
                src="./images/rechazadoIcon.svg"
                alt=""
              ></img>
            )}
          </div>
          <div
            className={gridView ? "item-title" : "item-title list"}
            style={{ color: "rgb(45, 59, 82)" }}
          >
            {
              <SpellingCorrection
                texto={formatearNombre(folderData?.Name)}
              ></SpellingCorrection>
            }
          </div>
          <div className="item-docList !mt-0">
            {createReport === "INVALID" ? (
              <div
                className="docList-title !mt-0"
                style={{
                  textAlign: "center",
                  fontSize: "1.1vw",
                  marginTop: "1vw",
                  fontStyle: "italic",
                }}
              >
                CIEC invalida, comunicate con el administrador.
              </div>
            ) : createReport === "PENDING" ? (
              <div
                className="docList-title !mt-0"
                style={{
                  textAlign: "center",
                  fontSize: "1.1vw",
                  marginTop: "1vw",
                  fontStyle: "italic",
                }}
              >
                Su reporte se está generando, por favor espere.
              </div>
            ) : (
              <div
                className="docList-title !mt-0"
                style={{
                  textAlign: "center",
                  fontSize: "1.1vw",
                  marginTop: "1vw",
                  fontStyle: "italic",
                }}
              >
                Haga clic aquí para generar su reporte...
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        open={openModal}
        setOpen={setOpenModal}
        action={onSubmit}
        title="Conexion al SAT para información financiera"
      >
        <form className="grid grid-cols-12 gap-4" onSubmit={onSubmit}>
          <div className="col-span-12">
            <Input
              label="RFC"
              placeholder="RFC"
              name="rfc"
              value={values.rfc}
              onChange={({ target: { value, name } }) => {
                if ((value && value?.length <= 13) || !value) {
                  const isValid = isValidRFC(value, checked);
                  setIsValidRfc(isValid);

                  newDataChange({ target: { name, value } });
                }
              }}
              classNameContainer={
                (!isValidRfc && userData.RFC) || error.type === "RFC"
                  ? "!border !border-red-400"
                  : ""
              }
              className={!isValidRfc && userData.RFC ? "!text-red-400" : ""}
            />
          </div>
          <div className="col-span-12">
            <InputPassword
              placeholder="Ingresa tu CIEC"
              name="ciec"
              label="Ingresa tu CIEC"
              autoComplete="off"
              value={values.ciec}
              onChange={newDataChange}
              classNameContainer={
                error?.type === "ciec" ? "!border-[#DC3545]" : ""
              }
            />
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default EkatenaRequest;
