import { HelpCircle } from "lucide-react";
import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const InputPlaces = ({
  value,
  setValue,
  placeholder = "",
  label,
  withTooltip,
  tooltipText,
  valueFull,
}) => {
  const [data, setData] = useState(null);
  return (
    <>
      {label && (
        <div className="input-label">
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill={"#1A222F"}
              color="white"
            ></HelpCircle>
          )}
        </div>
      )}
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBOFBkz-9u5DJjwFs0ipNf0RUgTcDEYyFk"
        autocompletionRequest={{
          componentRestrictions: {
            country: ["mx"],
          },
        }}
        selectProps={{
          value: Object.values(valueFull).length ? valueFull : data,
          onChange: (value) => {
            setData(value);
            setValue(value);
          },
          placeholder,
          className: "input-item !h-[45px] !border-none !outline-none mb-[1vw]",
          styles: {
            control: (provided) => ({
              ...provided,
              border: "0.1vw solid",
              borderColor: "black",
              boxShadow: "none",
              ":hover": {
                borderColor: "black",
                boxShadow: "none",
              },
              borderRadius: "8px",
              height: "45px",
              minHeight: 0,
              padding: "0vw .5vw",
              outline: "none",
            }),
            indicatorsContainer: () => ({
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              boxShadow: "none",
              border: "none",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              padding: "0",
            }),
            singleValue: (provided) => ({
              ...provided,
            }),
          },
        }}
        apiOptions={{
          language: "es",
          region: "mx",
        }}
      />
    </>
  );
};

export default InputPlaces;
