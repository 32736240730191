/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import DocumentItemList from "./DocumentItemList";
import CustomModal from "../../common/CustomModal";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import CustomLoader from "../../common/CustomLoader";
import LogoMuffin from "../../common/LogoMoffin";
import CustomProgressBar from "../../common/CustomProgressBar";
import CustomInput from "../../common/CustomInput";
import CustomMoffinModalTitle from "../../common/CustomMoffinModalTitle";
import { useForm } from "../../utils/useForm";
import { getFromSession } from "../../utils/sessionUtils";
import SpellingCorrection from "../../common/SpellingCorrection";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";

const FlujoMoffin = (props) => {
  const { folderData, gridView, setFolderSelected, sameAvalRL } = props;
  const [modalUploadDocs, setModalUploadDocs] = useState(false);
  const [modalUploadDocs2, setModalUploadDocs2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step1Selected, setStep1Selected] = useState(true);
  const [step2Selected, setStep2Selected] = useState(false);
  const [step3Selected, setStep3Selected] = useState(false);
  const dispatch = useDispatch();

  const [isRLsameOS, setisRLsameOS] = useState(false);

  const [randomNumber, setRandomNumber] = useState(generateRandomNumber());
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(true);

  function handleChange(event) {
    setUserInput(event.target.value);
  }

  const [estatusData, setEstatusData] = useState([]);

  function generateRandomNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }

  const [userData, newDataChange, ,] = useForm({
    Primer_nombre: "",
    Segundo_nombre: "",
    Primer_apellido: "",
    Segundo_apellido: "",
    Razon_social: "",
    CURP: "",
    Calle: "",
    Codigo_postal: "",
    Estado: "",
    Numero_exterior: "",
    Numero_interior: "",
    Colonia: "",
    Municipio: "",
    Fecha_de_nacimiento: "",
    Email_Aval: "",
    Email_Representante_Legal: "",
    Representante_legal_mismo_que_obligado_solidario: isRLsameOS ? "Si" : "No",
  });

  useEffect(() => {
    setEstatusData(folderData);
  }, [folderData]);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");
    const nombreFormateado = palabras.map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(" ");

    return nombreFormateado;
  }

  const changeToStep1 = () => {
    setStep1Selected(true);
    setStep2Selected(false);
    setStep3Selected(false);
  };

  const changeToStep2 = () => {
    setStep1Selected(false);
    setStep2Selected(true);
    setStep3Selected(false);
  };

  const changeToStep3 = () => {
    setStep1Selected(false);
    setStep2Selected(false);
    setStep3Selected(true);
  };

  const formatDateToBackend = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}/${month}/${day}`;
  };

  const autorizacionMoffin = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    if (parseInt(userInput) === randomNumber) {
      const res = await Request(
        "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
        {
          contact: {
            CURP: CURP.toUpperCase(),
            Raz_n_social: Razon_social,
            Fecha_de_nacimiento: Fecha_de_nacimiento ? formatDateToBackend(Fecha_de_nacimiento) : "", //nuevo YYYY/MM/DD
            Primer_nombre: Primer_nombre,
            Segundo_nombre: Segundo_nombre,
            Primer_apellido: Primer_apellido,
            Segundo_apellido: Segundo_apellido,
            Codigo_postal: Codigo_postal,
            Calle: Calle,
            Municipio: Municipio,
            Estado: Estado,
            Colonia: Colonia,
            Numero_exterior: Numero_exterior,
            Numero_interior: Numero_interior,
            Pa_s: "MX", //nuevo
          },
        },
        "PATCH",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      console.log("RESMOFFIN", res);
      let timerInterval;
      if (res.ok) {
        Swal.fire({
          icon: "success",
          title: "¡Éxito!",
          text: "Revise el estatus en su dashboard para más seguimiento",
          html: "Verá reflejado sus cambios en <b></b> segundos.",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const timer = Swal.getPopup().querySelector("b");
            timerInterval = setInterval(() => {
              timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
            }, 10);
          },
        }).then(() => setModalUploadDocs(false), setModalUploadDocs2(false), dispatch(fetchDataFoldersZoho(setLoading)));
      } else {
        Swal.fire({
          icon: "error",
          title: "¡Oh no algo salió mal!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error: el número ingresado no coincide. Inténtalo de nuevo.",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const autorizacionCorreoRLOS = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
      {
        contact: {
          Email_Aval: Email_Aval, //nuevo
          Email_Representante_Legal: Email_Representante_Legal, //nuevo
        },
      },
      "PATCH",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    console.log("RESCORREO", res);
    let timerInterval;
    if (res.ok) {
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Revise el estatus en su dashboard para más seguimiento",
        html: "Verá reflejado sus cambios en <b></b> segundos.",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.floor(Swal.getTimerLeft() / 1000)}`;
          }, 10);
        },
      }).then(() => setModalUploadDocs(false), setModalUploadDocs2(false), dispatch(fetchDataFoldersZoho(setLoading)));
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no algo salió mal!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const {
    Primer_nombre,
    Segundo_nombre,
    Primer_apellido,
    Segundo_apellido,
    Razon_social,
    Email_Aval,
    Fecha_de_nacimiento,
    CURP,
    Calle,
    Codigo_postal,
    Estado,
    Numero_exterior,
    Numero_interior,
    Colonia,
    Municipio,
    Email_Representante_Legal,
  } = userData;

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div
        className={gridView ? "seccionDocumentos-item" : "seccionDocumentos-item list"}
        onClick={
          sameAvalRL && estatusData?.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;"
            ? null
            : () =>
                estatusData?.Carpeta_superior === "Documentaci&oacute;n Express" && estatusData?.Estatus === "Pendiente"
                  ? setModalUploadDocs(true)
                  : estatusData?.Carpeta_superior !== "Documentaci&oacute;n Express"
                  ? setModalUploadDocs2(true)
                  : {}
        }
        style={
          sameAvalRL && estatusData?.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ?
          estatusData?.Estatus === "Pendiente"
            ? { border: "0.3vw solid #415575", position: "relative", opacity:0.5 }
            : estatusData?.Estatus === "En revisión"
            ? { backgroundColor: "#C8C7FE", border: "0.3vw solid #4944FC", position: "relative", opacity:0.5 }
            : estatusData?.Estatus === "Aprobado"
            ? { backgroundColor: "#f1fbf1", border: "0.3vw solid #73dc6f", position: "relative", opacity:0.5 }
            : estatusData?.Estatus === "Rechazado"
            ? { backgroundColor: "#FFFDE8", border: "0.3vw solid #E9E245", position: "relative", opacity:0.5 }
            : { border: "0.3vw solid #415575", position: "relative", opacity:0.5 }
            :
          estatusData?.Estatus === "Pendiente"
            ? { border: "0.3vw solid #415575", position: "relative" }
            : estatusData?.Estatus === "En revisión"
            ? { backgroundColor: "#C8C7FE", border: "0.3vw solid #4944FC", position: "relative" }
            : estatusData?.Estatus === "Aprobado"
            ? { backgroundColor: "#f1fbf1", border: "0.3vw solid #73dc6f", position: "relative" }
            : estatusData?.Estatus === "Rechazado"
            ? { backgroundColor: "#FFFDE8", border: "0.3vw solid #E9E245", position: "relative" }
            : { border: "0.3vw solid #415575", position: "relative" }
        }
      >
        <div
          style={
            gridView
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }
          }
        >
          <div className={gridView ? "item-icon-grid" : "item-icon-grid list"} style={{ marginLeft: "1vw" }}>
            {estatusData?.Estatus === "Pendiente" && <img className="iconItem" src="./images/nuevoIcon.svg" alt=""></img>}
            {estatusData?.Estatus === "En revisión" && <img className="iconItem" src="./images/esperaIcon.svg" alt=""></img>}
            {estatusData?.Estatus === "Aprobado" && <img className="iconItem" src="./images/aceptadoIcon.svg" alt=""></img>}
            {estatusData?.Estatus === "Rechazado" && <img className="iconItem" src="./images/rechazadoIcon.svg" alt=""></img>}
          </div>
          {gridView && (
            <div className="moffin-logo-container" style={{ position: "absolute", right: "1vw", top: "1vw" }}>
              <LogoMuffin></LogoMuffin>
            </div>
          )}
          {!gridView && (
            <div className="moffin-logo-container" style={{ position: "absolute", left: "1vw" }}>
              <LogoMuffin></LogoMuffin>
            </div>
          )}

          <div
            className={gridView ? "item-title" : "item-title list"}
            style={
              estatusData?.Estatus === "Pendiente" || estatusData?.Estatus === "Rechazado"
                ? { color: "#2D3B52", fontSize: "0.9vw" }
                : { fontSize: "0.9vw" }
            }
          >
            {<SpellingCorrection texto={formatearNombre(folderData.Name)}></SpellingCorrection>}
          </div>
          {gridView && (
            <>
              {sameAvalRL && estatusData?.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
                <div className="docList-title" style={{ textAlign: "center", marginTop: "1vw", fontSize: "1.1vw", fontStyle: "italic" }}>
                  Autorización desde el Representante Legal
                </div>
              ) : (
                <div className="item-docList">
                  {estatusData?.Estatus === "Pendiente" && (
                    <div
                      className="docList-title"
                      style={{
                        textAlign: "center",
                        fontSize: "1.1vw",
                        marginTop: "1vw",
                        fontStyle: "italic",
                      }}
                    >
                      Haga clic aquí para autorizar con Moffin la consulta a su buró de crédito...
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {!gridView && (
            <>
              {sameAvalRL && estatusData?.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ? (
                <div className="docList-title" style={{ textAlign: "center", fontSize: "1.1vw", fontStyle: "italic" }}>
                  Autorización desde el Representante Legal
                </div>
              ) : (
                <div className="item-docList" style={{ width: "50%" }}>
                  {estatusData?.Estatus === "Pendiente" && (
                    <div className="docList-title" style={{ textAlign: "center", fontSize: "1.2vw" }}>
                      Haga clic aquí para autorizar con Moffin la consulta a su buró de crédito...
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <CustomModal
        open={modalUploadDocs}
        setOpen={setModalUploadDocs}
        title={"Solicitud de revisión a buró de crédito"}
        withBtnsFooter={false}
        strongTitle={true}
        fromMoffin={true}
        extraText={"Confirma que los datos del solicitante sean correctos"}
      >
        <>
          <div className="container-progressBar" style={{ width: "70%", marginTop: 0 }}>
            <CustomProgressBar porcent={step1Selected ? 0 : step2Selected ? 25 : step3Selected ? 50 : 75} fromMoffin={true}></CustomProgressBar>
          </div>
          <div className="modal-container" style={{ width: "100%", justifyContent: "space-between", height: "35vw" }}>
            <div className="steps-container">
              <div className="sept1-container" style={step2Selected || step3Selected ? { display: "none" } : { width: "100%" }}>
                <CustomMoffinModalTitle
                  title={"Solicitud de revisión a buró de crédito"}
                  subtitle={"Confirma que los datos del solicitante sean correctos"}
                  withHelp={true}
                  helpText={
                    "Finerik utiliza la tecnología de Moffin para automatizar las consultas de buró de crédito, tus datos están completamente seguros."
                  }
                ></CustomMoffinModalTitle>
                {localStorage.getItem("layout") === "Persona Fisica" && (
                  <div className="form-twoColumns-container">
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Primer nombre"
                        placeholder="Primer nombre"
                        name="Primer_nombre"
                        value={Primer_nombre}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Segundo nombre"
                        placeholder="Segundo nombre"
                        name="Segundo_nombre"
                        value={Segundo_nombre}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Primer apellido"
                        placeholder="Primer apellido"
                        name="Primer_apellido"
                        value={Primer_apellido}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Segundo apellido"
                        placeholder="Segundo apellido"
                        name="Segundo_apellido"
                        value={Segundo_apellido}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Fecha de Nacimiento (DD/MM/YYYY)"
                        placeholder="Fecha de nacimiento (DD/MM/YYYY)"
                        name="Fecha_de_nacimiento"
                        value={Fecha_de_nacimiento}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                  </div>
                )}
                {localStorage.getItem("layout") === "Persona Moral" && (
                  <div className="form-twoColumns-container">
                    <div className="input-twoColumns-container">
                      <CustomInput
                        styles={{ color: "black" }}
                        label="Razón social o nombre"
                        placeholder="Razon social"
                        name="Razon_social"
                        value={Razon_social}
                        onChange={newDataChange}
                      ></CustomInput>
                    </div>
                  </div>
                )}
              </div>
              <div className="sept2-container" style={step1Selected || step3Selected ? { display: "none" } : { width: "100%" }}>
                <CustomMoffinModalTitle
                  title={"Solicitud de revisión a buró de crédito"}
                  subtitle={"Confirma que los datos del solicitante sean correctos"}
                  withHelp={true}
                  helpText={
                    "Finerik utiliza la tecnología de Moffin para automatizar las consultas de buró de crédito, tus datos están completamente seguros."
                  }
                ></CustomMoffinModalTitle>
                {localStorage.getItem("layout") === "Persona Fisica" && (
                  <div style={{ height: "22vw", overflow: "auto" }}>
                    <div className="subtitle-datos-moffin">Datos Generales</div>
                    <div className="form-twoColumns-container">
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="CURP"
                          placeholder="CURP"
                          name="CURP"
                          value={CURP}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                    </div>
                    <div className="subtitle-datos-moffin" style={{ marginTop: "1vw" }}>
                      Domicilio
                    </div>
                    <div className="form-twoColumns-container">
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Calle"
                          placeholder="Calle"
                          name="Calle"
                          value={Calle}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "48%" }}>
                          <CustomInput
                            styles={{ color: "black" }}
                            label="Número exterior"
                            placeholder="Número exterior"
                            name="Numero_exterior"
                            value={Numero_exterior}
                            onChange={newDataChange}
                          ></CustomInput>
                        </div>
                        <div style={{ width: "48%" }}>
                          <CustomInput
                            styles={{ color: "black" }}
                            label="Número interior"
                            placeholder="Número interior"
                            name="Numero_interior"
                            value={Numero_interior}
                            onChange={newDataChange}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Código Postal"
                          placeholder="CP"
                          name="Codigo_postal"
                          value={Codigo_postal}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Colonia"
                          placeholder="Colonia"
                          name="Colonia"
                          value={Colonia}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Estado"
                          placeholder="Estado"
                          name="Estado"
                          value={Estado}
                          onChange={newDataChange}
                          type="select"
                          options={[
                            {
                              label: "Seleccione un estado",
                              value: "",
                            },
                            {
                              label: "Aguascalientes",
                              value: "AGU",
                            },
                            {
                              label: "Baja California",
                              value: "BCN",
                            },
                            {
                              label: "Baja California Sur",
                              value: "BCS",
                            },
                            {
                              label: "Campeche",
                              value: "CAM",
                            },
                            {
                              label: "Chiapas",
                              value: "CHP",
                            },
                            {
                              label: "Chihuahua",
                              value: "CHH",
                            },
                            {
                              label: "Ciudad de México",
                              value: "CMX",
                            },
                            {
                              label: "Coahuila",
                              value: "COA",
                            },
                            {
                              label: "Colima",
                              value: "COL",
                            },
                            {
                              label: "Durango",
                              value: "DUR",
                            },
                            {
                              label: "Guanajuato",
                              value: "GUA",
                            },
                            {
                              label: "Guerrero",
                              value: "GRO",
                            },
                            {
                              label: "Hidalgo",
                              value: "HID",
                            },
                            {
                              label: "Jalisco",
                              value: "JAL",
                            },
                            {
                              label: "Estado de México",
                              value: "MEX",
                            },
                            {
                              label: "Michoacán",
                              value: "MIC",
                            },
                            {
                              label: "Morelos",
                              value: "MOR",
                            },
                            {
                              label: "Nayarit",
                              value: "NAY",
                            },
                            {
                              label: "Nuevo León",
                              value: "NLE",
                            },
                            {
                              label: "Oaxaca",
                              value: "OAX",
                            },
                            {
                              label: "Puebla",
                              value: "PUE",
                            },
                            {
                              label: "Querétaro",
                              value: "QUE",
                            },
                            {
                              label: "Quintana Roo",
                              value: "ROO",
                            },
                            {
                              label: "San Luís Potosí",
                              value: "SLP",
                            },
                            {
                              label: "Sinaloa",
                              value: "SIN",
                            },
                            {
                              label: "Sonora",
                              value: "SON",
                            },
                            {
                              label: "Tabasco",
                              value: "TAB",
                            },
                            {
                              label: "Tamaulipas",
                              value: "TAM",
                            },
                            {
                              label: "Tlaxcala",
                              value: "TLA",
                            },
                            {
                              label: "Veracruz",
                              value: "VER",
                            },
                            {
                              label: "Yucatán",
                              value: "YUC",
                            },
                            {
                              label: "Zacatecas",
                              value: "ZAC",
                            },
                          ]}
                        ></CustomInput>
                        {/* <CustomInput
                          styles={{ color: "black" }}
                          label="Estado"
                          placeholder="Estado"
                          name="Estado"
                          value={Estado}
                          onChange={newDataChange}
                        ></CustomInput> */}
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Delegación/Municipio"
                          placeholder="Delegación/Municipio"
                          name="Municipio"
                          value={Municipio}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                    </div>
                  </div>
                )}
                {localStorage.getItem("layout") === "Persona Moral" && (
                  <div style={{ height: "22vw", overflow: "auto" }}>
                    <div className="form-twoColumns-container">
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Calle"
                          placeholder="Calle"
                          name="Calle"
                          value={Calle}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "48%" }}>
                          <CustomInput
                            styles={{ color: "black" }}
                            label="Número exterior"
                            placeholder="Número exterior"
                            name="Numero_exterior"
                            value={Numero_exterior}
                            onChange={newDataChange}
                          ></CustomInput>
                        </div>
                        <div style={{ width: "48%" }}>
                          <CustomInput
                            styles={{ color: "black" }}
                            label="Número interior"
                            placeholder="Número interior"
                            name="Numero_interior"
                            value={Numero_interior}
                            onChange={newDataChange}
                          ></CustomInput>
                        </div>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Código Postal"
                          placeholder="CP"
                          name="Codigo_postal"
                          value={Codigo_postal}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Colonia"
                          placeholder="Colonia"
                          name="Colonia"
                          value={Colonia}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container">
                      <CustomInput
                          styles={{ color: "black" }}
                          label="Estado"
                          placeholder="Estado"
                          name="Estado"
                          value={Estado}
                          onChange={newDataChange}
                          type="select"
                          options={[
                            {
                              label: "Seleccione un estado",
                              value: "",
                            },
                            {
                              label: "Aguascalientes",
                              value: "AGU",
                            },
                            {
                              label: "Baja California",
                              value: "BCN",
                            },
                            {
                              label: "Baja California Sur",
                              value: "BCS",
                            },
                            {
                              label: "Campeche",
                              value: "CAM",
                            },
                            {
                              label: "Chiapas",
                              value: "CHP",
                            },
                            {
                              label: "Chihuahua",
                              value: "CHH",
                            },
                            {
                              label: "Ciudad de México",
                              value: "CMX",
                            },
                            {
                              label: "Coahuila",
                              value: "COA",
                            },
                            {
                              label: "Colima",
                              value: "COL",
                            },
                            {
                              label: "Durango",
                              value: "DUR",
                            },
                            {
                              label: "Guanajuato",
                              value: "GUA",
                            },
                            {
                              label: "Guerrero",
                              value: "GRO",
                            },
                            {
                              label: "Hidalgo",
                              value: "HID",
                            },
                            {
                              label: "Jalisco",
                              value: "JAL",
                            },
                            {
                              label: "Estado de México",
                              value: "MEX",
                            },
                            {
                              label: "Michoacán",
                              value: "MIC",
                            },
                            {
                              label: "Morelos",
                              value: "MOR",
                            },
                            {
                              label: "Nayarit",
                              value: "NAY",
                            },
                            {
                              label: "Nuevo León",
                              value: "NLE",
                            },
                            {
                              label: "Oaxaca",
                              value: "OAX",
                            },
                            {
                              label: "Puebla",
                              value: "PUE",
                            },
                            {
                              label: "Querétaro",
                              value: "QUE",
                            },
                            {
                              label: "Quintana Roo",
                              value: "ROO",
                            },
                            {
                              label: "San Luís Potosí",
                              value: "SLP",
                            },
                            {
                              label: "Sinaloa",
                              value: "SIN",
                            },
                            {
                              label: "Sonora",
                              value: "SON",
                            },
                            {
                              label: "Tabasco",
                              value: "TAB",
                            },
                            {
                              label: "Tamaulipas",
                              value: "TAM",
                            },
                            {
                              label: "Tlaxcala",
                              value: "TLA",
                            },
                            {
                              label: "Veracruz",
                              value: "VER",
                            },
                            {
                              label: "Yucatán",
                              value: "YUC",
                            },
                            {
                              label: "Zacatecas",
                              value: "ZAC",
                            },
                          ]}
                        ></CustomInput>
                      </div>
                      <div className="input-twoColumns-container">
                        <CustomInput
                          styles={{ color: "black" }}
                          label="Delegación/Municipio"
                          placeholder="Delegación/Municipio"
                          name="Municipio"
                          value={Municipio}
                          onChange={newDataChange}
                        ></CustomInput>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="sept3-container" style={step1Selected || step2Selected ? { display: "none" } : { width: "100%" }}>
                <CustomMoffinModalTitle title={"Autorización de consulta de historial crediticio"} subtitle={""}></CustomMoffinModalTitle>
                <div style={{ height: "17vw", overflow: "auto", backgroundColor: "#0b131e", padding: "1vw", borderRadius: "1vw" }}>
                  <div className="autorizacion-moffin-parrafo">
                    Por este conducto autorizo expresamente a Moffin Software S.A.P.I. de C.V., para que por conducto de sus funcionarios facultados
                    lleve a cabo Investigaciones, sobre mi comportamiento crediticio o el de la Empresa que represento en Trans Union de México, S. A.
                    SIC y/o Dun & Bradstreet, S.A. SIC.
                  </div>
                  <div className="autorizacion-moffin-parrafo">
                    Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que Moffin Software S.A.P.I.
                    de C.V., hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial o el de la empresa que
                    represento, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de su expedición y
                    en todo caso durante el tiempo que se mantenga la relación jurídica
                  </div>
                  <div className="autorizacion-moffin-parrafo">
                    En caso de que la solicitante sea una Persona Moral, declaro bajo protesta de decir verdad Ser Representante Legal de la empresa
                    mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido
                    revocados, limitados, ni modificados en forma alguna.
                  </div>
                </div>
                <div className="form-twoColumns-container">
                  <div className="input-twoColumns-container">
                    <div>
                      Código de registro es: <span style={{ fontWeight: 700, color: "#9be69f" }}>{randomNumber}</span>
                    </div>
                    <span style={{ fontStyle: "italic", fontSize: "0.8vw" }}>Ingrésalo en el siguiente campo de texto para continuar.</span>
                  </div>
                  <div className="input-twoColumns-container">
                    <CustomInput
                      styles={{ color: "black" }}
                      label="Código"
                      placeholder="Código"
                      name="codigo"
                      value={userInput}
                      onChange={handleChange}
                    ></CustomInput>
                    {!isValid && <p style={{ color: "red" }}>Error: el número ingresado no coincide.</p>}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="support-container"
              style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0, marginTop: "2vw" }}
            >
              <div
                className="btn-nuevaSolicitud"
                style={{ width: "35%" }}
                onClick={() => (step1Selected ? setModalUploadDocs(false) : step2Selected ? changeToStep1() : step3Selected ? changeToStep2() : {})}
              >
                {step1Selected || step3Selected ? "Cancelar" : "Regresar"}
              </div>
              <div
                className="btn-nuevaSolicitud"
                style={{ width: "35%" }}
                onClick={() => (step1Selected ? changeToStep2() : step2Selected ? changeToStep3() : step3Selected ? autorizacionMoffin() : {})}
              >
                {step3Selected ? "Autorizar" : "Continuar"}
              </div>
            </div>
          </div>
        </>
      </CustomModal>
      <CustomModal
        open={modalUploadDocs2}
        setOpen={setModalUploadDocs2}
        title={"Solicitud de revisión a buró de crédito"}
        withBtnsFooter={false}
        strongTitle={true}
      >
        <div className="modal-container" style={{ width: "100%", height: "32vw", justifyContent: "space-between" }}>
          <div className="sept1-container" style={step2Selected || step3Selected ? { display: "none" } : { width: "100%" }}>
            <CustomMoffinModalTitle
              title={""}
              subtitle={"A continuación ingrese un correo donde podamos contactar al Aval o Representante Legal para poder pedirle sus datos."}
              withHelp={false}
            ></CustomMoffinModalTitle>
            <div className="form-twoColumns-container">
              <div className="input-twoColumns-container">
                {folderData.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" && (
                  <CustomInput
                    styles={{ color: "black" }}
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    name="Email_Aval"
                    value={Email_Aval}
                    onChange={newDataChange}
                  ></CustomInput>
                )}
                {folderData.Carpeta_superior !== "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" && (
                  <CustomInput
                    styles={{ color: "black" }}
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    name="Email_Representante_Legal"
                    value={Email_Representante_Legal}
                    onChange={newDataChange}
                  ></CustomInput>
                )}
              </div>
            </div>
          </div>
          <div
            className="support-container"
            style={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0, marginTop: "2vw" }}
          >
            <div
              className="btn-nuevaSolicitud"
              style={{ width: "35%" }}
              onClick={() => (step1Selected ? setModalUploadDocs2(false) : step2Selected ? changeToStep1() : step3Selected ? changeToStep2() : {})}
            >
              {step1Selected || step3Selected ? "Cancelar" : "Regresar"}
            </div>
            <div
              className="btn-nuevaSolicitud"
              style={{ width: "35%" }}
              onClick={() =>
                estatusData?.Carpeta_superior === "Documentos de aval&#x2f;obligado solidario &#x28;OS&#x29;" ||
                estatusData?.Carpeta_superior === "Documentos del representante legal &#x28;RL&#x29;"
                  ? autorizacionCorreoRLOS()
                  : autorizacionMoffin()
              }
            >
              {step3Selected ? "Autorizar" : "Continuar"}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

FlujoMoffin.propTypes = {};

export default FlujoMoffin;
