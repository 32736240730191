import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
// import Onboarding from "./modules/Onboarding/Onboarding";
import OnBoarding from "./pages/on-boarding/quote";
import CargaDocumental from "./modules/PortalCargaDocumentos/Dashboard";
import Inicio from "./modules/PortalCliente/Inicio";
import HistorialSolicitudes from "./modules/PortalCliente/HistorialSolicitudes";
// import { useSession } from "./utils/sessionUtils";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import Login from "./pages/auth/login";
import ApplicationResponse from "./pages/on-boarding/response-request";
import PreliminaryQuote from "./pages/on-boarding/response-request/preliminary-quote";
import ViewLeasing from "./pages/assets/leasing";
import Forgot from "./pages/auth/forgot";

const App = () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/auth/login"} />} />
        <Route path="*" element={<Navigate to={"/auth/login"} />} />
        <Route exact path="/on-boarding" element={<OnBoarding />} />
        <Route exact path="/dashboard" element={<Inicio />} />
        <Route exact path="/carga-documental" element={<CargaDocumental />} />
        <Route
          exact
          path="/historial-solicitudes"
          element={<HistorialSolicitudes />}
        />
        <Route
          exact
          path="/application-response"
          element={<ApplicationResponse />}
        />
        <Route exact path="/preliminary-quote" element={<PreliminaryQuote />} />
        <Route exact path="/auth/login" element={<Login />} />
        <Route exact path="/auth/forgot" element={<Forgot />} />
        <Route exact path="/leasing" element={<ViewLeasing />} />
      </Routes>
    </Router>
  );
};

export default App;
