import React, { useEffect, useState } from "react";
import "rc-steps/assets/index.css";
import { ChevronsLeft, Edit, HelpCircle } from "lucide-react";
import CustomProgressBar from "../../common/CustomProgressBar";
import Switch from "react-switch";
import { Tooltip } from "react-tooltip";
import CustomInput from "../../common/CustomInput";
import CustomButton from "../../common/CustomButton";
import { useForm } from "../../utils/useForm";
import Request from "../../utils/fetchUtils";
import CustomLoader from "../../common/CustomLoader";
import {
  Checkbox,
  Input,
  InputPhoneCountry,
  InputPlace,
} from "../../components";
import {
  convertStringValid,
  isValidRFC,
  moralRequired,
  requiredSteps,
  isPasswordSecure,
} from "../../utils";
import CustomCodeInput from "../../common/CustomCodeInput";
import Swal from "sweetalert2";
import { BASE_URLS } from "../../utils/fetchUtils";
import { InputPassword, PasswordMinRequired } from "../../components";
import InfoDescription from "../../components/InfoDescription";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/fetch-v2";

const OnBoarding = (props) => {
  // const { setQuotationInfo } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [stepSelect, setStepSelect] = useState(0);
  const [isValidRfc, setIsValidRfc] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmarContraseña, setConfirmarContraseña] = useState("");
  const [contraseñasCoinciden, setContraseñasCoinciden] = useState(true);
  const [cumpleRequisitos, setCumpleRequisitos] = useState(true);
  const [addressFull, setAddressFull] = useState({});
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState({ text: "", type: null });
  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [checkedRfc, setCheckedRfc] = useState(true);

  useEffect(() => {
    const handleBeforeCloseWindow = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeCloseWindow);

    return () =>
      window.removeEventListener("beforeunload", handleBeforeCloseWindow);
  }, []);

  const newDataChange = (e) => {
    setError({ text: "", type: null });
    handleChangeData(e);
  };

  const [userData, handleChangeData] = useForm({
    Deal_Name: "",
    email: "",
    Stage: "Recepcion",
    Type: "",
    Telefono: "",
    Razon_Social: "",
    Ubicacion: "",
    An_os_operando: 0,
    Facturacion_anual: "",
    Situacion_fiscal: "",
    RFC: "",
    Giro_de_negocio: "",
    Estado_en_buro: "",
    Tipo_de_servicio: "",
    tipo_de_industria: "",
    Descripcion_del_equipo: "",
    Valor_del_equipo: "",
    Plazo_de_interes: "",
    Comprar_activo: "",
    trigger: ["approval", "workflow", "blueprint"],
  });

  const handleChangeSwitch = (checked) => {
    setChecked(checked);
  };

  const validationRfc = async () => {
    const response = await axiosInstance.get(`/user/validate-rfc?rfc=${RFC}`);
    if (response.data) {
      return response.data;
    }
  };

  const onSubmitStep = async () => {
    if (stepSelect === 4) return verifyEmail();

    const next = stepSelect + 1;

    if (stepSelect === 2) {
      setLoading(true);
      setCheckedRfc(true);
      const val = await validationRfc();
      setLoading(false);
      if (val && !val?.validation) return setCheckedRfc(false);
    }

    let stepsValidation = requiredSteps[`step${stepSelect}`];

    if (checked && stepSelect === 2)
      stepsValidation = [...moralRequired, ...stepsValidation];

    for (let i = 0; i < stepsValidation.length; i++) {
      if (
        stepsValidation[i] === "password" &&
        (!password ||
          !confirmarContraseña ||
          !contraseñasCoinciden ||
          !cumpleRequisitos)
      ) {
        setError({ text: `Verifica tu información`, type: "password" });
        return;
      }

      if (
        stepsValidation[i] === "Telefono" &&
        userData[stepsValidation[i]]?.length <= 3
      ) {
        setError({
          text: `No has llenado la información ${
            convertStringValid[stepsValidation[i]]
          }`,
          type: stepsValidation[i],
        });
        return;
      }

      if (
        (stepsValidation[i] !== "password" &&
          userData[stepsValidation[i]]?.length <= 0) ||
        (stepsValidation[i] === "An_os_operando" &&
          userData[stepsValidation[i]] <= 0)
      ) {
        setError({
          text: `No has llenado la información ${
            convertStringValid[stepsValidation[i]]
          }`,
          type: stepsValidation[i],
        });
        return;
      }
    }

    if (stepSelect === 2 && !isValidRfc) return;

    setError({ text: "", type: null });

    if (next === 4) {
      signUp();
      return;
    }

    setStepSelect(next);
  };

  const onBackStep = () => {
    setError({ text: "", type: null });
    const back = stepSelect - 1;

    if (back > 0) {
      setStepSelect(back);
      return;
    }
  };

  const saveChanges = async () => {
    const tokenJwt = localStorage.getItem("jwt");
    setLoading(true);
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/contact",
      {
        contact: {
          dedupId: Math.random() * (100 - 1) + 1,
          email: email,
          Deal_Name: Deal_Name,
          Type: checked ? "Persona Moral" : "Persona Fisica",
          Telefono: Telefono,
          Raz_n_social: Razon_Social,
          Ubicacion: Ubicacion,
          An_os_operando: An_os_operando,
          Facturacion_anual: Facturacion_anual,
          Situacion_fiscal: Situacion_fiscal,
          RFC: RFC,
          Giro_de_negocio: Giro_de_negocio,
          Estado_en_buro: Estado_en_buro,
          Tipo_de_servicio: Tipo_de_servicio,
          tipo_de_industria: tipo_de_industria,
          Descripcion_del_equipo: Descripcion_del_equipo,
          Valor_del_equipo: Valor_del_equipo.replace(/[^0-9.]/g, ""),
          Plazo_de_interes: Plazo_de_interes,
          Comprar_activo: Comprar_activo,
          trigger: ["approval", "workflow", "blueprint"],
        },
      },
      "POST",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (res.status) {
      localStorage.setItem("name", Deal_Name);
      localStorage.setItem("idSolicitud", res.zoho.data[0].details.id);
      createDeal(res.zoho.data[0].details.id);
    }
  };

  const createDeal = async (idSolicitud) => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/deal?id=" +
        idSolicitud,
      {
        deal: {
          dedupId: Math.random() * (100 - 1) + 1,
          email: email,
          Deal_Name: Deal_Name,
          Type: checked ? "Persona Moral" : "Persona Fisica",
          Telefono: Telefono,
          Raz_n_social: Razon_Social,
          Ubicacion: Ubicacion,
          An_os_operando: An_os_operando,
          Facturacion_anual: Facturacion_anual,
          Situacion_fiscal: Situacion_fiscal,
          RFC: RFC,
          Giro_de_negocio: Giro_de_negocio,
          Estado_en_buro: Estado_en_buro,
          Tipo_de_servicio: Tipo_de_servicio,
          tipo_de_industria: tipo_de_industria,
          Descripcion_del_equipo: Descripcion_del_equipo,
          Valor_del_equipo: Valor_del_equipo.replace(/[^0-9.]/g, ""),
          Plazo_de_interes: Plazo_de_interes,
          Comprar_activo: Comprar_activo,
          trigger: ["approval", "workflow", "blueprint"],
        },
      },
      "POST",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (res.status) {
      getPreApproval();
    }
  };

  const getPreApproval = async () => {
    setLoading(true);
    const res = await Request(
      "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/onboarding-preapproval",
      {
        email: email,
        score: {
          An_os_operando: An_os_operando,
          Facturacion_anual: Facturacion_anual,
          Situacion_fiscal: Situacion_fiscal,
          Situacion_crediticia: Estado_en_buro,
        },
        quotation: {
          tipo_de_industria: tipo_de_industria,
          Valor_del_equipo: Valor_del_equipo.replace(/[^0-9.]/g, ""),
          Plazo_de_interes: Plazo_de_interes,
        },
      },
      "POST"
    );
    setLoading(false);
    if (res.status === "success") {
      if (res.message === "APROBADO") {
        await login(false);
        setLoading(false);
        localStorage.setItem("QUOTE-RESPONSE", JSON.stringify(res.quotation));
      }

      navigate("/application-response");
    }
  };

  const signUp = async () => {
    setLoading(true);
    if (contraseñasCoinciden && cumpleRequisitos) {
      const res = await Request(
        BASE_URLS.GENERAL + "/staging/auth/signup",
        {
          email: userData.email,
          password: password,
        },
        "POST"
      );
      if (res.status) {
        setLoading(false);
        setStepSelect(4);
      } else if (res.message === "User already exists") {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title:
            res.message === "User already exists"
              ? "Ese usuario ya existe"
              : "¡Oh no algo salió mal! Inténtalo de nuevo",
          showConfirmButton: true,
        });
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Hay un problema con tu contraseña",
        text: "Revisa que tus contraseñas coincidan y/o que cumplan con los requisitos.",
        showConfirmButton: true,
      });
    }
  };

  const verifyEmail = async () => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/verify-email",
      {
        email: email,
        confirmationCode: codigo,
      },
      "POST"
    );
    if (res.status) {
      login();
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const login = async (save = true) => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/login",
      {
        email: email,
        password: password,
      },
      "POST"
    );
    if (res.status) {
      const { token: jwt, userAttributes } = res;
      localStorage.setItem("jwt", jwt);
      localStorage.setItem("email", userAttributes.email);
      localStorage.setItem("onboardingCompleted", true);
      localStorage.setItem("sUserData", JSON.stringify(userAttributes));
      localStorage.setItem("zohoContactId", userAttributes.zohoContactId);
      const resContact = await Request(
        BASE_URLS.GENERAL + "/staging/back-office/user/contact",
        {},
        "GET",
        false,
        false,
        {
          Authorization: `Bearer ${jwt}`,
        }
      );
      if (resContact.status) {
        localStorage.setItem("name", resContact.contact.Full_Name);
        localStorage.setItem("layout", resContact.contact.Layout);
        localStorage.setItem(
          "avalMismoRL",
          resContact.contact.Representante_legal_mismo_que_obligado_solidario
        );
        localStorage.setItem(
          "tipoServicio",
          resContact.contact?.Solicitudes[0]?.Tipo_de_servicio
        );
        localStorage.setItem(
          "Solicitudes",
          JSON.stringify(resContact.contact?.Solicitudes)
        );
      }

      if (save) saveChanges();
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const {
    Deal_Name,
    Telefono,
    Razon_Social,
    Situacion_fiscal,
    Ubicacion,
    Giro_de_negocio,
    An_os_operando,
    RFC,
    Facturacion_anual,
    Estado_en_buro,
    Tipo_de_servicio,
    tipo_de_industria,
    Valor_del_equipo,
    Descripcion_del_equipo,
    Plazo_de_interes,
    Comprar_activo,
    email,
  } = userData;

  return (
    <div className="min-h-screen w-full bg-[#f6f4ea] p-4 flex justify-center items-center">
      <CustomLoader isLoading={loading} />
      <div className="fixed right-2 bottom-0">
        <img className="selloAnimado" src="/SelloAnimado.svg" alt="animation" />
      </div>
      <div className="w-full grid grid-cols-12 place-content-center">
        <div className="col-span-12 lg:col-span-6 md:col-span-6 lg:col-start-4 md:col-start-4">
          <div className="border border-[#1a222f] rounded-lg shadow-custom-1">
            {stepSelect === 0 ? (
              <div className="flex justify-center ">
                <div className="w-full flex flex-col justify-center px-8 py-4">
                  <div className="bg-[#73dc6f] h-[56px] lg:hidden md:hidden -mx-8 -mt-4" />
                  <div className="image-container">
                    <img
                      className="iconView"
                      src="./images/bienvenidoIcon.svg"
                      alt="welcome icon"
                    ></img>
                  </div>
                  <div className="title-container text-center">
                    <span>Bienvenido</span>
                  </div>
                  <div className="text-container lg:px-4">
                    ¡Bienvenido a bordo! Este sencillo proceso nos ayudará a
                    conocerte a tí y a tu empresa para brindarte la opción
                    perfecta. Son 3 pasos muy breves, ¡te lo prometemos!
                  </div>
                  <div className="termsPrivacy-container lg:px-4">
                    Al continuar aceptas nuestros{" "}
                    <a
                      className="termsPrivacy"
                      href="https://www.finerik.com/terminos-y-condiciones"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Términos y Condiciones
                    </a>{" "}
                    y nuestro{" "}
                    <a
                      className="termsPrivacy"
                      href="https://www.finerik.com/aviso-de-privacidad"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aviso de Privacidad
                    </a>
                    .
                  </div>
                  <div className="leasing-container text-center">
                    <div
                      className="leasingText-btn"
                      onClick={() => navigate("/leasing")}
                    >
                      Ok, pero ¿Qué es Leasing?
                    </div>
                  </div>
                  <div className="flex items-center justify-center mb-3">
                    <div className="welcome-btn-container h-[45px] max-sm:!w-full max-sm:!h-[45px] ">
                      <CustomButton
                        text={"Continuar"}
                        style={{ height: "100%" }}
                        onClick={() => setStepSelect(stepSelect + 1)}
                      ></CustomButton>
                    </div>
                  </div>
                </div>
                {/* <div className="btnAction-container max-sm:hidden">
                  <div
                    className="btn"
                    onClick={() => setStepSelect(stepSelect + 1)}
                  >
                    <img
                      className="btnNext"
                      src="/images/nextBtn.svg"
                      alt="altBtn"
                    ></img>
                  </div>
                </div> */}
              </div>
            ) : (
              <div className="flex justify-between w-full min-h-[471px]">
                <div className="w-full flex flex-col justify-center px-8 py-12">
                  <div className="bg-[#73dc6f] h-[56px] lg:hidden md:hidden -mx-8 -mt-12" />
                  <div className="header-container">
                    <div className="back-container" onClick={onBackStep}>
                      {stepSelect > 1 && (
                        <ChevronsLeft className="backButton"></ChevronsLeft>
                      )}
                    </div>
                  </div>
                  {stepSelect > 0 && stepSelect < 4 && (
                    <div className="container-progressBar">
                      <CustomProgressBar
                        porcent={
                          stepSelect === 1 ? 25 : stepSelect === 2 ? 50 : 100
                        }
                      ></CustomProgressBar>
                    </div>
                  )}
                  {stepSelect === 1 ? (
                    <StepPersonalData
                      handleChangeSwitch={handleChangeSwitch}
                      checked={checked}
                      Deal_Name={Deal_Name}
                      email={email}
                      newDataChange={newDataChange}
                      Telefono={Telefono}
                      setLoading={setLoading}
                      Plazo_de_interes={Plazo_de_interes}
                      Tipo_de_servicio={Tipo_de_servicio}
                      tipo_de_industria={tipo_de_industria}
                      Valor_del_equipo={Valor_del_equipo}
                      contraseñasCoinciden={contraseñasCoinciden}
                      setContraseñasCoinciden={setContraseñasCoinciden}
                      confirmarContraseña={confirmarContraseña}
                      setConfirmarContraseña={setConfirmarContraseña}
                      password={password}
                      setPassword={setPassword}
                      setCumpleRequisitos={setCumpleRequisitos}
                      cumpleRequisitos={cumpleRequisitos}
                      error={error}
                      setError={setError}
                    />
                  ) : stepSelect === 2 ? (
                    <StepInformationComplement
                      Estado_en_buro={Estado_en_buro}
                      userData={userData}
                      setIsValidRfc={setIsValidRfc}
                      Facturacion_anual={Facturacion_anual}
                      isValidRfc={isValidRfc}
                      isValidRFC={isValidRFC}
                      RFC={RFC}
                      checkedRfc={checkedRfc}
                      setCheckedRfc={setCheckedRfc}
                      checked={checked}
                      Razon_Social={Razon_Social}
                      newDataChange={newDataChange}
                      Situacion_fiscal={Situacion_fiscal}
                      Ubicacion={Ubicacion}
                      Giro_de_negocio={Giro_de_negocio}
                      An_os_operando={An_os_operando}
                      setAddressFull={setAddressFull}
                      addressFull={addressFull}
                      error={error}
                    />
                  ) : stepSelect === 3 ? (
                    <StepAssetsInformation
                      userData={userData}
                      password={password}
                      setStepSelect={setStepSelect}
                      error={error}
                      checked={checkedConfirm}
                      setChecked={setCheckedConfirm}
                    />
                  ) : stepSelect === 4 ? (
                    <StepVerifiedCode setCodigo={setCodigo} />
                  ) : null}

                  <div className="mt-4">
                    {/* {error && (
                      <div className="text-sm text-red-400 mb-2">{error}</div>
                    )} */}
                    <div className="welcome-btn-container h-[45px] max-sm:!w-full max-sm:!h-[45px] mt-3">
                      <CustomButton
                        text={
                          stepSelect === 4 ? "Generar cotización" : "Continuar"
                        }
                        style={{ height: "100%" }}
                        disabled={!checkedConfirm && stepSelect === 3}
                        onClick={onSubmitStep}
                      ></CustomButton>
                    </div>
                  </div>
                </div>

                {/* <div className="btnAction-container max-sm:hidden">
                  <div className="btn" onClick={onSubmitStep}>
                    <img
                      className="btnNext"
                      src="/images/nextBtn.svg"
                      alt="altBtn"
                    ></img>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StepPersonalData = ({
  handleChangeSwitch,
  checked,
  Deal_Name,
  newDataChange,
  Telefono,
  email,
  Plazo_de_interes,
  Tipo_de_servicio,
  tipo_de_industria,
  Valor_del_equipo,
  contraseñasCoinciden,
  setContraseñasCoinciden,
  confirmarContraseña,
  setConfirmarContraseña,
  password,
  setPassword,
  setCumpleRequisitos,
  error,
  setError,
}) => {
  const handleInputChange = (e) => {
    setError("");
    const { name, value } = e.target;

    if (name === "password") {
      setPassword(value);
    } else if (name === "passwordConfirm") {
      setConfirmarContraseña(value);
    }

    const isStrong = isPasswordSecure(value);
    setCumpleRequisitos(isStrong);

    // Compara las contraseñas y actualiza el estado de contraseñasCoinciden
    setContraseñasCoinciden(value === password);
  };

  return (
    <div className="sept1-container">
      <div className="title-container-register" style={{ textAlign: "center" }}>
        <span>Inicia con tu pre-aprobación</span>
      </div>
      <div className="text-container" style={{ textAlign: "center" }}>
        <div>Ingresa tus datos personales para conocerte mejor.</div>
      </div>
      <div className="switch-person-container">
        <div className="switch-container">
          <Switch
            className="switch-person"
            onChange={handleChangeSwitch}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            onHandleColor={"#4944FC"}
            offHandleColor={"#4944FC"}
            offColor="#fff"
            onColor="#fff"
            borderRadius={5}
          ></Switch>
        </div>
        <div className="personType-container">
          <div style={checked ? {} : { color: "#4944FC" }}>PFAE</div>
          <div
            style={
              checked
                ? {
                    color: "#4944FC",
                    display: "flex",
                    alignItems: "center",
                  }
                : { display: "flex", alignItems: "center" }
            }
          >
            Moral{" "}
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Por favor, selecciona el perfil que mejor se adapte a tu situación:
· Persona física con actividad empresarial: Para aquellos que realizan actividades empresariales de forma independiente.
· Persona moral: Para entidades legalmente constituidas, como sociedades o empresas."
              className="help-icon"
              fill={"#1A222F"}
              color="white"
            ></HelpCircle>
          </div>
          <Tooltip
            id="my-tooltip"
            style={{
              whiteSpace: "break-spaces",
              backgroundColor: "#1A222F",
              fontWeight: 300,
              fontSize: "1vw",
              width: "20vw",
              padding: "2vw",
            }}
            place="right"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-3">
        <div className="col-span-12 lg:col-span-6">
          <Input
            label="Nombre completo"
            placeholder="Representante legal"
            name="Deal_Name"
            value={Deal_Name}
            onChange={newDataChange}
            classNameContainer={
              error?.type === "Deal_Name" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Deal_Name"
                ? "Ingresa el nombre de representante legal"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputPhoneCountry
            label="Teléfono"
            placeholder="(xxx) xx-xx-xx"
            value={Telefono}
            onChange={(phone) => {
              newDataChange({ target: { name: "Telefono", value: phone } });
            }}
            classNameContainer={
              error?.type === "Telefono" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Telefono"
                ? "Ingresa un número de teléfono valido"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-12">
          <Input
            placeholder="Correo electrónico"
            name="email"
            type="email"
            label="Ingresa tu correo"
            value={email}
            onChange={newDataChange}
            classNameContainer={
              error?.type === "email" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "email"
                ? "Ingresa un correo electrónico valido"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputPassword
            placeholder="Contraseña"
            name="password"
            type="password"
            label="Crea tu contraseña"
            value={password}
            styles={{
              border: contraseñasCoinciden
                ? "2px solid green"
                : "2px solid red",
            }}
            onChange={handleInputChange}
            classNameContainer={
              error?.type === "password" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "password"
                ? "Ingresa una contraseña válida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputPassword
            placeholder="Confirmar Contraseña"
            name="passwordConfirm"
            type="password"
            label="Confirma tu contraseña"
            value={confirmarContraseña}
            withSpecs
            styles={{
              border: contraseñasCoinciden
                ? "2px solid green"
                : "2px solid red",
            }}
            onChange={handleInputChange}
            classNameContainer={
              error?.type === "password" ? "!border-[#DC3545]" : ""
            }
            error={error?.type === "password" ? "" : undefined}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          {!contraseñasCoinciden &&
            password.length > 0 &&
            confirmarContraseña.length > 0 && (
              <p style={{ color: "red" }} className="text-sm mb-1">
                Las contraseñas no coinciden.
              </p>
            )}
          <PasswordMinRequired value={password} />
        </div>
      </div>
      <div className="my-4">
        <div className="border-t border-t-black mb-3" />
        <div className="text-lg text-center">
          Cuéntanos sobre el activo que necesitas
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-3 mt-4">
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="Tipo de servicio"
            placeholder="Selecciona una opción"
            name="Tipo_de_servicio"
            type="select"
            onChange={newDataChange}
            value={Tipo_de_servicio}
            withTooltip={true}
            tooltipText={
              "Leaseback: Compramos tus activos para capitalizar tu empresa. Los activos seguirán siendo parte de tu operación durante todo el plazo. \n \nArrendamiento: Consigue el activo nuevo que necesitas para hacer crecer tu empresa sin descapitalizarte. Además, cambia el activo por uno nuevo al final del plazo."
            }
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Arrendamiento", //Leasing
                value: "Leasing",
              },
              {
                label: "Sales & Leaseback",
                value: "Leaseback",
              },
            ]}
            classNameContainer={
              error?.type === "Tipo_de_servicio" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Tipo_de_servicio"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="Tipo de activo"
            placeholder="Selecciona una opción"
            name="tipo_de_industria"
            type="select"
            onChange={newDataChange}
            value={tipo_de_industria}
            withTooltip={true}
            tooltipText={
              "Elige la categoría del activo que necesitas para entender mejor la solicitud."
            }
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Cómputo",
                value: "Cómputo",
              },
              {
                label: "Movilidad",
                value: "Movilidad",
              },
              {
                label: "Maquinaria",
                value: "Maquinaria",
              },
              {
                label: "Motocicletas",
                value: "Motocicletas",
              },
              {
                label: "Salud",
                value: "Salud",
              },
              {
                label: "Energía",
                value: "Energía",
              },
              {
                label: "Carritos de golf",
                value: "Carritos de golf",
              },
            ]}
            classNameContainer={
              error?.type === "tipo_de_industria" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "tipo_de_industria"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="Valor del equipo ($)"
            placeholder="Ingresa la cantidad del valor del equipo"
            name="Valor_del_equipo"
            type="money"
            value={Valor_del_equipo}
            onChange={newDataChange}
            classNameContainer={
              error?.type === "Valor_del_equipo" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Valor_del_equipo"
                ? "Ingresa un valor"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="¿A cuántos plazos te interesa?"
            placeholder="Selecciona una opción"
            name="Plazo_de_interes"
            type="select"
            onChange={newDataChange}
            value={Plazo_de_interes}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "12 meses",
                value: "12 meses",
              },
              {
                label: "24 meses",
                value: "24 meses",
              },
              {
                label: "36 meses",
                value: "36 meses",
              },
              {
                label: "48 meses",
                value: "48 meses",
              },
            ]}
            classNameContainer={
              error?.type === "Plazo_de_interes" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Plazo_de_interes"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

const StepInformationComplement = ({
  Estado_en_buro,
  userData,
  setIsValidRfc,
  Facturacion_anual,
  isValidRfc,
  isValidRFC,
  RFC,
  checked,
  Razon_Social,
  newDataChange,
  Situacion_fiscal,
  Ubicacion,
  Giro_de_negocio,
  An_os_operando,
  setAddressFull,
  addressFull,
  error,
  checkedRfc,
  setCheckedRfc,
}) => {
  return (
    <div className="sept2-container">
      <div className="title-container-register" style={{ textAlign: "center" }}>
        <span>Cuéntanos quien eres</span>
      </div>
      <div className="text-container" style={{ textAlign: "center" }}>
        <div className="switch-person-container">
          <div className="switch-container">
            <Switch
              className="switch-person"
              onChange={() => {}}
              checked={checked}
              uncheckedIcon={false}
              checkedIcon={false}
              onHandleColor={"#4944FC"}
              offHandleColor={"#4944FC"}
              offColor="#fff"
              onColor="#fff"
              borderRadius={5}
            ></Switch>
          </div>
          <div className="personType-container">
            <div style={checked ? {} : { color: "#4944FC" }}>PFAE</div>
            <div
              style={
                checked
                  ? {
                      color: "#4944FC",
                      display: "flex",
                      alignItems: "center",
                    }
                  : { display: "flex", alignItems: "center" }
              }
            >
              Moral{" "}
              <HelpCircle
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Por favor, selecciona el perfil que mejor se adapte a tu situación:
· Persona física con actividad empresarial: Para aquellos que realizan actividades empresariales de forma independiente.
· Persona moral: Para entidades legalmente constituidas, como sociedades o empresas."
                className="help-icon"
                fill={"#1A222F"}
                color="white"
              ></HelpCircle>
            </div>
            <Tooltip
              id="my-tooltip"
              style={{
                whiteSpace: "break-spaces",
                backgroundColor: "#1A222F",
                fontWeight: 300,
                fontSize: "1vw",
                width: "20vw",
                padding: "2vw",
              }}
              place="right"
            />
          </div>
          <div>
            {checked
              ? "Ingresa la siguiente información para conocer a tu empresa mejor"
              : "Ingresa la siguiente información para conocerte mejor"}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-3 mt-4">
        <div className="col-span-12 lg:col-span-12">
          {checked && (
            <CustomInput
              label="Razón social"
              placeholder="Razon social"
              name="Razon_Social"
              value={Razon_Social}
              onChange={newDataChange}
              classNameContainer={
                error?.type === "Razon_Social" ? "!border-[#DC3545]" : ""
              }
              error={
                error?.type === "Razon_Social"
                  ? "Ingresa tu razón social"
                  : undefined
              }
            />
          )}
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="Situación Fiscal"
            placeholder="Selecciona tu situación fiscal"
            name="Situacion_fiscal"
            type="select"
            onChange={newDataChange}
            value={Situacion_fiscal}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Están al día",
                value: "Están al día",
              },
              {
                label: "Están atrasados máximo 2 meses",
                value: "Están atrasados máximo 2 meses",
              },
              {
                label: "Tiene más de 2 meses de atraso",
                value: "Tiene más de 2 meses de atraso",
              },
            ]}
            classNameContainer={
              error?.type === "Situacion_fiscal" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Situacion_fiscal"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InputPlace
            label="Domicilio"
            value={Ubicacion}
            valueFull={addressFull}
            placeholder="Domicilio"
            setValue={(value) => {
              setAddressFull(value);
              newDataChange({
                target: { name: "Ubicacion", value: value?.label },
              });
            }}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="Giro de negocio"
            placeholder="Seleccione una opción"
            name="Giro_de_negocio"
            type="select"
            onChange={newDataChange}
            value={Giro_de_negocio}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Comercial",
                value: "Comercial",
              },
              {
                label: "Industrial",
                value: "Industrial",
              },
              {
                label: "Servicios",
                value: "Servicios",
              },
              {
                label: "Agropecuaria",
                value: "Agropecuaria",
              },
              {
                label: "Empresas de viajes y turismo",
                value: "Empresas de viajes y turismo",
              },
              {
                label: "Entretenimiento",
                value: "Entretenimiento",
              },
              {
                label: "Hospedaje",
                value: "Hospedaje",
              },
              {
                label: "Logística",
                value: "Logística",
              },
              {
                label: "Mayorista",
                value: "Mayorista",
              },
              {
                label: "Minorista",
                value: "Minorista",
              },
              {
                label: "Manufactura",
                value: "Manufactura",
              },
              {
                label: "Servicios profesionales",
                value: "Servicios profesionales",
              },
              {
                label: "Telecomunicaciones",
                value: "Telecomunicaciones",
              },
              {
                label: "Importación y/o exportación",
                value: "Importación y/o exportación",
              },
              {
                label: "Otros servicios",
                value: "Otros servicios",
              },
            ]}
            classNameContainer={
              error?.type === "Giro_de_negocio" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Giro_de_negocio"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="¿Cuántos años llevas operando?"
            placeholder="Seleccione una opción"
            name="An_os_operando"
            type="select"
            onChange={newDataChange}
            value={An_os_operando}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "1 año",
                value: "1 año",
              },
              {
                label: "2 años",
                value: "2 años",
              },
              {
                label: "3 años",
                value: "3 años",
              },
              {
                label: "4 años",
                value: "4 años",
              },
              {
                label: "5 años o más",
                value: "5 años o más",
              },
            ]}
            classNameContainer={
              error?.type === "An_os_operando" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "An_os_operando"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-x-3 items-end">
            <div className="col-span-12 lg:col-span-6">
              <Input
                label="RFC"
                placeholder="RFC"
                name="RFC"
                value={RFC}
                onChange={({ target: { value, name } }) => {
                  setCheckedRfc(true);
                  if ((value && value?.length <= 13) || !value) {
                    const isValid = isValidRFC(value, checked);
                    setIsValidRfc(isValid);

                    newDataChange({ target: { name, value } });
                  }
                }}
                classNameContainer={
                  (!isValidRfc && userData.RFC) || error.type === "RFC"
                    ? "!border !border-red-400"
                    : ""
                }
                className={!isValidRfc && userData.RFC ? "!text-red-400" : ""}
              />
              {!checkedRfc && (
                <div className="text-sm text-red-400 -mt-2 mb-2">
                  El RFC ya fue registrado
                </div>
              )}
            </div>
            <div className="col-span-12 lg:col-span-6">
              <CustomInput
                label="¿Cuál es la factura anual promedio de tu empresa?"
                placeholder="Seleccione una opción"
                name="Facturacion_anual"
                type="select"
                onChange={newDataChange}
                value={Facturacion_anual}
                options={[
                  {
                    label: "Seleccione una opción",
                    value: "",
                  },
                  {
                    label: "De 2 a 5 mdp",
                    value: "De 2 a 5 mdp",
                  },
                  {
                    label: "De 5 a 20 mdp",
                    value: "De 5 a 20 mdp",
                  },
                  {
                    label: "De 20 a 50 mdp",
                    value: "De 20 a 50 mdp",
                  },
                  {
                    label: "Más de 50 mdp",
                    value: "Más de 50 mdp",
                  },
                ]}
                classNameContainer={
                  error?.type === "Facturacion_anual" ? "!border-[#DC3545]" : ""
                }
                error={
                  error?.type === "Facturacion_anual"
                    ? "Selecciona una opción valida"
                    : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <CustomInput
            label="¿Cómo consideras que está el buró de tu empresa?"
            placeholder="Seleccione una opción"
            name="Estado_en_buro"
            type="select"
            onChange={newDataChange}
            value={Estado_en_buro}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Nunca me han financiado",
                value: "Nunca me han financiado",
              },
              {
                label: "Tengo atrasos en los últimos 3 años que no son graves",
                value: "Tengo atrasos en los últimos 3 años que no son graves",
              },
              {
                label: "Tengo atrasos graves",
                value: "Tengo atrasos graves",
              },
              {
                label: "Está perfecto",
                value: "Está perfecto",
              },
              {
                label: "No lo conozco",
                value: "No lo conozco",
              },
            ]}
            classNameContainer={
              error?.type === "Estado_en_buro" ? "!border-[#DC3545]" : ""
            }
            error={
              error?.type === "Estado_en_buro"
                ? "Selecciona una opción valida"
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

const StepAssetsInformation = ({
  userData,
  password,
  setStepSelect,
  checked,
  setChecked,
}) => {
  return (
    <div className="sept3-container">
      <div className="title-container-register" style={{ textAlign: "center" }}>
        <span>Confirmación</span>
      </div>
      <div className="grid grid-cols-12 gap-x-3 gap-y-3 mt-4">
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Nombre completo:"
            info={userData?.Deal_Name}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Teléfono:"
            info={userData?.Telefono}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Correo electrónico:"
            info={userData?.email}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Contraseña:"
            pass
            info={password}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 mt-4 mb-2">
          <label className="text-xl">Información de activos:</label>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Tipo de servicio:"
            info={userData?.Tipo_de_servicio}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Tipo de activo:"
            info={userData?.tipo_de_industria}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Valor del equipo:"
            info={userData?.Valor_del_equipo}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Plazo:"
            info={userData?.Plazo_de_interes}
            onClick={() => {}}
          />
        </div>

        <div className="col-span-12 flex justify-end">
          <div
            className="flex flex-row gap-x-3 cursor-pointer"
            onClick={() => setStepSelect(1)}
          >
            <div>Editar</div>
            <Edit />
          </div>
        </div>
      </div>
      <div className="border-t border-gray-400 my-4" />
      <div className="grid grid-cols-12 gap-x-3 gap-y-3 mt-4">
        {userData?.Razon_Social?.length > 0 && (
          <div className="col-span-12 lg:col-span-6">
            <InfoDescription
              label="Razón social:"
              info={userData?.Razon_Social}
              onClick={() => {}}
            />
          </div>
        )}
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Situación Fiscal:"
            info={userData?.Situacion_fiscal}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Ubicación:"
            info={userData?.Ubicacion}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Giro de negocio:"
            info={userData?.Giro_de_negocio}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Años que llevas operando:"
            info={userData?.An_os_operando}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="RFC:"
            info={userData?.RFC}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Facturación anual promedio de tu empresa:"
            info={userData?.Facturacion_anual}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <InfoDescription
            label="Buró de tu empresa:"
            info={userData?.Estado_en_buro}
            onClick={() => {}}
          />
        </div>
        <div className="col-span-12 flex justify-end">
          <div
            className="flex flex-row gap-x-3 cursor-pointer"
            onClick={() => setStepSelect(2)}
          >
            <div>Editar</div>
            <Edit />
          </div>
        </div>

        <div className="col-span-12 flex flex-col justify-start my-4">
          <div className="flex flex-row gap-x-3 cursor-pointer">
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              label="Confirmo que actúo por cuenta propia y que soy dueño de los recursos y que éstos han sido obtenidos o generados a través de una fuente de origen lícito."
            />
          </div>
          <div className="bg-purple-200 p-3 rounded-md text-sm">
            Con estos datos crearemos tu cuenta, por favor ayúdanos a que todo
            se encuentre en orden.
          </div>
        </div>
      </div>
    </div>
  );
};

const StepVerifiedCode = (props) => {
  const { setCodigo } = props;

  const actualizarCodigo = (nuevoCodigo) => {
    setCodigo(nuevoCodigo);
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-x-3 gap-y-3 mt-4">
        <div className="col-span-12">
          <div className="header-container">
            <div className="image-container">
              <img
                className="iconView"
                src="./images/codeIcon.svg"
                alt="code icon"
              ></img>
            </div>
          </div>
          <div className="title-container !text-center">
            <span>Verifica tu correo electrónico</span>
          </div>
          <div className="text-center">
            Te enviamos un código a tu correo electrónico, introdúcelo a
            continuación.
          </div>
          <div className="verifyCode-container">
            <CustomCodeInput
              actualizarCodigo={actualizarCodigo}
            ></CustomCodeInput>
            {/* <div className="resendCode-container">Reenviar código</div> */}
          </div>
        </div>
        <div className="btnAction-container">
          <div className="btn">
            <img
              className="btnNext"
              src="./images/nextBtn.svg"
              alt="next button"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};

// const StepRegister = ({ setLoading, onBackStep }) => {
//   const [password, setPassword] = useState("");
//   const [confirmarContraseña, setConfirmarContraseña] = useState("");
//   const [contraseñasCoinciden, setContraseñasCoinciden] = useState(true);
//   const [cumpleRequisitos, setCumpleRequisitos] = useState(true);
//   const [userData, newDataChange] = useForm({
//     email: "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "password") {
//       setPassword(value);
//     } else if (name === "passwordConfirm") {
//       setConfirmarContraseña(value);
//     }

//     const isStrong = isPasswordSecure(value);
//     setCumpleRequisitos(isStrong);

//     // Compara las contraseñas y actualiza el estado de contraseñasCoinciden
//     setContraseñasCoinciden(value === password);
//   };

//   const signUp = async () => {
//     setLoading(true);
//     if (contraseñasCoinciden && cumpleRequisitos) {
//       const res = await Request(
//         BASE_URLS.GENERAL + "/staging/auth/signup",
//         {
//           email: email,
//           password: password,
//         },
//         "POST"
//       );

//       if (res.status) {
//         localStorage.setItem("emailGiven", res.email);
//         setLoading(false);
//         // Swal.fire({
//         //   icon: "success",
//         //   title: "¡Su registro ha sido exitoso!",
//         //   text: "Se enviará un código a su correo para continuar con la validación de la cuenta.",
//         //   showConfirmButton: false,
//         //   timer: 3000,
//         // }).then(() => setSection("VerifyCode"));
//       } else if (res.message === "User already exists") {
//         // setSection("VerifyCode");
//       } else {
//         setLoading(false);
//         Swal.fire({
//           icon: "error",
//           title:
//             res.message === "User already exists"
//               ? "Ese usuario ya existe"
//               : "¡Oh no algo salió mal! Inténtalo de nuevo",
//           showConfirmButton: true,
//         });
//       }
//     } else {
//       setLoading(false);
//       Swal.fire({
//         icon: "error",
//         title: "Hay un problema con tu contraseña",
//         text: "Revisa que tus contraseñas coincidan y/o que cumplan con los requisitos.",
//         showConfirmButton: true,
//       });
//     }
//   };
//   const { email } = userData;

//   return (
//     <div className="sept2-container">
//       <div className="">
//         <div
//           className="title-container-register"
//           style={{ textAlign: "center" }}
//         >
//           <span>Felicidades</span>
//         </div>
//         <div className="text-container" style={{ textAlign: "center" }}>
//           <div>
//             Estás a un paso de obtener tu cotización, completa los datos
//             solicitados.
//           </div>
//         </div>
//         <div className="form-container-register">
//           <Input
//             placeholder="Correo electrónico"
//             name="email"
//             type="email"
//             label="Ingresa tu correo"
//             value={email}
//             onChange={newDataChange}
//             icon={<AtSign></AtSign>}
//           />
//           <InputPassword
//             placeholder="Contraseña"
//             name="password"
//             type="password"
//             label="Crea tu contraseña"
//             value={password}
//             styles={{
//               border: contraseñasCoinciden
//                 ? "2px solid green"
//                 : "2px solid red",
//             }}
//             onChange={handleInputChange}
//             icon={<KeyRound></KeyRound>}
//           />
//           <InputPassword
//             placeholder="Confirmar Contraseña"
//             name="passwordConfirm"
//             type="password"
//             label="Confirma tu contraseña"
//             value={confirmarContraseña}
//             withSpecs
//             styles={{
//               border: contraseñasCoinciden
//                 ? "2px solid green"
//                 : "2px solid red",
//             }}
//             onChange={handleInputChange}
//             icon={<KeySquare></KeySquare>}
//           />
//           {!contraseñasCoinciden &&
//             password.length > 0 &&
//             confirmarContraseña.length > 0 && (
//               <p style={{ color: "red" }} className="text-sm mb-1">
//                 Las contraseñas no coinciden.
//               </p>
//             )}
//           <PasswordMinRequired value={password} />
//         </div>
//         {/* <div className="welcome-btn-container">
//           <CustomButton
//             text="Continuar"
//             onClick={() => {
//               signUp();
//             }}
//           ></CustomButton>
//         </div> */}
//       </div>
//     </div>
//   );
// };

export default OnBoarding;
