import React from "react";
import { useNavigate } from "react-router-dom";
import { Home, FileEdit, Bell, PowerOff, MonitorSmartphone, FileClock } from "lucide-react";
import { endSession } from "../utils/sessionUtils";
import CustomProfileImage from "./CustomProfileImage";
import useStore from "../zustand/useStore";

const Menu = (props) => {
  const navigate = useNavigate();
  const { tipoMenu, setTipoMenu } = useStore();

  return (
    <div className="menu-container">
      <div>
        <div className="logo-container">
          <img className="logoMenu" src="./images/LogoWhite.svg" alt="logo"></img>
        </div>
        <div className="userInfo-container">
          <div className="profilePhoto-container">
            <CustomProfileImage name={localStorage.getItem("name")}></CustomProfileImage>
          </div>
          <div className="name-container">{localStorage.getItem("name")}</div>
        </div>
        <div className={tipoMenu === "Dashboard" ? "item-general-container activo" : "item-general-container"}>
          <div className="vistaMenu">
            <div className="vistaMenu-up"></div>
          </div>
          <div
            className="itemMenu-container"
            id={tipoMenu === "Dashboard" && "itemMenu-container"}
            onClick={() => {
              setTipoMenu("Dashboard");
              navigate("/dashboard");
            }}
          >
            <div className="iconMenu-container">
              <Home className="iconMenu" />
            </div>
            <div className="textMenu-container">Inicio</div>
          </div>
          <div className="vistaMenu">
            <div className="vistaMenu-down"></div>
          </div>
        </div>
        <div className={tipoMenu === "CargaDocumental" ? "item-general-container activo" : "item-general-container"}>
          <div className="vistaMenu">
            <div className="vistaMenu-up"></div>
          </div>
          <div
            className="itemMenu-container"
            id={tipoMenu === "CargaDocumental" && "itemMenu-container"}
            onClick={() => {
              setTipoMenu("CargaDocumental");
              navigate("/carga-documental");
            }}
          >
            <div className="iconMenu-container">
              <FileEdit className="iconMenu" />
            </div>
            <div className="textMenu-container">Carga Documental</div>
          </div>
          <div className="vistaMenu">
            <div className="vistaMenu-down"></div>
          </div>
        </div>
        <div className={tipoMenu === "HistorialSolicitudes" ? "item-general-container activo" : "item-general-container"}>
          <div className="vistaMenu">
            <div className="vistaMenu-up"></div>
          </div>
          <div
            className="itemMenu-container"
            id={tipoMenu === "HistorialSolicitudes" && "itemMenu-container"}
            onClick={() => {
              setTipoMenu("HistorialSolicitudes");
              navigate("/historial-solicitudes");
            }}
          >
            <div className="iconMenu-container">
              <FileClock className="iconMenu" />
            </div>
            <div className="textMenu-container">Historial de Solicitudes</div>
          </div>
          <div className="vistaMenu">
            <div className="vistaMenu-down"></div>
          </div>
        </div>
        {/* <div className="item-general-container">
          <div className="vistaMenu">
            <div className="vistaMenu-up"></div>
          </div>
          <div
            className="itemMenu-container"
            onClick={() => {
              navigate("/misClases");
            }}
          >
            <div className="iconMenu-container">
              <Bell className="iconMenu" />
            </div>
            <div className="textMenu-container">Notificaciones</div>
          </div>
          <div className="vistaMenu">
            <div className="vistaMenu-down"></div>
          </div>
        </div> */}
      </div>
      <div>
        {/* <div className="support-container">
          <div className="btn-nuevaSolicitud">Nueva Solicitud</div>
        </div>
        <div className="logout-container">
          <MonitorSmartphone></MonitorSmartphone>
          <div className="btn-soporte">Contactar a Soporte</div>
        </div> */}
        <button className="logout-container" onClick={() => endSession()}>
          <PowerOff></PowerOff>
          <div className="btn-logout">Cerrar Sesión</div>
        </button>
      </div>
    </div>
  );
};

Menu.propTypes = {};

export default Menu;
