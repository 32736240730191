import React from "react";
import CustomButton from "../../../common/CustomButton";
import { useNavigate } from "react-router-dom";

const RejectRequest = (props) => {
  const navigate = useNavigate();

  return (
    <div className="congrats-container">
      <div className="rejected-icon-container">
        <img
          className="iconView"
          src="./images/rejectedIcon.svg"
          alt="reject icon"
        ></img>
      </div>
      <div className="congrats-subtitle-container">
        ¡Gracias por haber aplicado!
      </div>
      <div className="text-info">
        Agradecemos mucho tu interés en este producto. Hemos analizado las
        respuestas de tu aplicación y lamentamos informarte que por el momento
        no podremos ayudarte con tu solicitud. Esperamos trabajar muy pronto
        juntos.
      </div>
      <div className="welcome-btn-container">
        <CustomButton
          text="Finalizar"
          onClick={() => {
            localStorage.clear();
            navigate("/auth/login");
          }}
        ></CustomButton>
      </div>
    </div>
  );
};

export default RejectRequest;
