import axios from "axios";

console.log(process.env.REACT_APP_URL_SERVICES);
const AxiosConfig = {
  baseURL: process.env.REACT_APP_URL_SERVICES,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 307;
  },
};

const Axios = axios.create(AxiosConfig);
Axios.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem("jwt");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // if (error.response !== undefined) {
    //   if (error.response.status === 401) {
    //     const resp = await getAuthToken();
    //     authStore.set(resp.access_token);
    //   }
    // }

    return Promise.reject(error);
  }
);

export const axiosInstance = Axios;
