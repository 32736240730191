import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";

const InfoDescription = ({ label, info, onClick, pass }) => {
  const [password, setPassword] = useState(false);
  return (
    <div className="flex flex-col items-start" onClick={onClick}>
      <label className="text-xl">{label}</label>
      <div className={`text-lg text-gray-600 ${pass ? "flex gap-x-3" : ""}`}>
        {pass ? (
          <>
            {password ? info : "********"}
            {password ? (
              <EyeOff onClick={() => setPassword(!password)} />
            ) : (
              <Eye onClick={() => setPassword(!password)} />
            )}
          </>
        ) : (
          <>{info}</>
        )}
      </div>
    </div>
  );
};
export default InfoDescription;
