import React, { useState } from "react";
import { Eye, EyeOff, HelpCircle } from "lucide-react";

const CustomInput = (props) => {
  const {
    placeholder,
    name,
    type = "text",
    icon,
    disabled = false,
    label,
    options = [],
    maxLength,
    minLength,
    value,
    onChange,
    withTooltip = false,
    tooltipText = " ",
    styles = {},
    classNameContainer = "",
    error = "",
  } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // const handleChange = (e) => {
  //   if (type === "money") {
  //     let monto = e.target.value.replace(/\D/g, "");
  //     monto = new Intl.NumberFormat("es-ES", {
  //       style: "currency",
  //       currency: "USD",
  //       minimumFractionDigits: 2,
  //     }).format(monto / 100);
  //     onChange({ ...e, target: { ...e.target, value: monto } });
  //   } else {
  //     onChange(e);
  //   }
  // };

  return (
    <>
      {label && (
        <div className="input-label">
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill={"#1A222F"}
              color="white"
            ></HelpCircle>
          )}
        </div>
      )}
      <div
        className={`${
          icon ? "input-container" : "input-container2"
        } ${classNameContainer}`}
        style={type === "textarea" ? { height: "fit-content" } : {}}
      >
        {icon && <div className="icon-container">{icon}</div>}
        {type === "text" ||
        type === "password" ||
        type === "tel" ||
        type === "money" ? (
          <input
            className="input-item"
            type={
              type === "password"
                ? passwordVisible
                  ? "text"
                  : "password"
                : type === "money"
                ? "text"
                : type
            }
            placeholder={placeholder}
            name={name}
            disabled={disabled}
            maxLength={maxLength}
            minLength={minLength}
            value={value}
            onChange={onChange}
            style={styles}
          ></input>
        ) : type === "textarea" ? (
          <textarea
            className="input-item"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{ paddingTop: "1vw", minHeight: "6vw" }}
          ></textarea>
        ) : type === "select" ? (
          <>
            <select
              className="input-item"
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            >
              {" "}
              {options.map((o) => (
                <option value={o.value}>{o.label}</option>
              ))}
            </select>
            <i></i>
          </>
        ) : (
          <></>
        )}
        {type === "password" && passwordVisible && (
          <EyeOff
            style={{
              position: "absolute",
              right: "7vw",
              cursor: "pointer",
              width: "1.5vw",
            }}
            onClick={togglePasswordVisibility}
          ></EyeOff>
        )}
        {type === "password" && !passwordVisible && (
          <Eye
            style={{
              position: "absolute",
              right: "7vw",
              cursor: "pointer",
              width: "1.5vw",
            }}
            onClick={togglePasswordVisibility}
          ></Eye>
        )}
      </div>
      {error && <div className="text-red-500 text-sm -mt-2 mb-2">{error}</div>}
    </>
  );
};

CustomInput.propTypes = {};

export default CustomInput;
