import React from "react";

const CustomLoader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div className="loader-container">
      <img
        className="selloAnimado"
        style={{ display: "block" }}
        src="/SelloAnimado.svg"
        alt="animation"
      ></img>
    </div>
  );
};

export default CustomLoader;
