import React from "react";
import CustomButton from "../../../common/CustomButton";
import CustomProgressBar from "../../../common/CustomProgressBar";
import { useNavigate } from "react-router-dom";

const ViewCongrats = (props) => {
  const navigate = useNavigate();

  return (
    <div className="congrats-container">
      <div className="congrats-icon-container">
        <img
          className="iconView"
          src="./images/congratsIcon.svg"
          alt="congradulation icon"
        ></img>
      </div>
      <div className="stepsBar-container">
        <CustomProgressBar
          porcent={100}
          finishProgress={true}
        ></CustomProgressBar>
      </div>
      <div className="congrats-title-container">¡Felicidades!</div>
      <div className="congrats-subtitle-container">Has sido pre-aprobado</div>
      <div className="text-info">
        Hemos preparado una pre-cotización para tí, pulsa el siguiente botón
        para descargarla
      </div>
      <div className="welcome-btn-container">
        <CustomButton
          text="Descargar"
          onClick={() => {
            navigate("/preliminary-quote");
          }}
        ></CustomButton>
      </div>
      <div className="welcome-btn-container">
        <CustomButton
          text="Continuar"
          onClick={() => {
            navigate("/carga-documental");
          }}
        ></CustomButton>
      </div>
    </div>
  );
};

export default ViewCongrats;
