import { ChevronsLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../common/CustomButton";

const ViewForgotPassword3 = (props) => {
  const { slider } = props;
  const navigate = useNavigate();

  return (
    <div className="welcome-container" slider={slider}>
      <div className="info-container">
        <div className="header-container">
          <div
            className="back-container"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <ChevronsLeft className="backButton"></ChevronsLeft>
          </div>
          <div className="image-container">
            <img className="iconView" src="/images/forgotPass3Icon.svg"></img>
          </div>
        </div>
        <div className="title-container-register">
          <span>¡Contraseña actualizada!</span>
        </div>
        <div className="info-text-container">
          ¡Buenas noticias! Tu contraseña ha sido actualizada correctamente,
          puedes continuar.
        </div>
        <div className="welcome-btn-container">
          <CustomButton
            text="Continuar"
            onClick={() => {
              navigate("/auth/login");
            }}
          ></CustomButton>
        </div>
      </div>
      <div className="btnAction-container">
        <div
          className="btn"
          onClick={() => {
            navigate("/auth/login");
          }}
        >
          <img className="btnNext" src="/images/nextBtn.svg"></img>
        </div>
      </div>
    </div>
  );
};

export default ViewForgotPassword3;
