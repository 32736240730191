import React, { useEffect } from "react";

const CustomCodeInput = ({ actualizarCodigo }) => {
  const renderInputsFun = () => {
    const inputs = document.querySelectorAll(".inputsCode-container input");

    const handleInput = (event, index) => {
      const { value } = event.target;
      if (value.length > 1) {
        event.target.value = value.slice(0, 1);
      }

      if (index < inputs.length - 1 && value.length === 1) {
        inputs[index + 1].focus();
      }

      const codigo = Array.from(inputs)
        .map((input) => input.value)
        .join(""); // Concatenar los dígitos
      actualizarCodigo(codigo);
    };

    inputs.forEach((input, index) => {
      input.addEventListener("input", (event) => handleInput(event, index));
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handleInput);
      });
    };
  };

  useEffect(() => {
    renderInputsFun();
  }, []);

  const handlePaste = (event) => {
    const inputs = document.querySelectorAll(".inputsCode-container input");
    const paste = event.clipboardData.getData("text") || "";
    if (paste.length > 0 && inputs) {
      const decode = Array.from(paste);
      inputs.forEach((input, index) => {
        input.value = decode[index];
        inputs[index].focus();
      });
    }
  };

  return (
    <div className="inputsCode-container max-sm:!flex max-sm:!flex-wrap max-sm:!mt-4">
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
      <input
        className="code-input max-sm:!w-[40px] max-sm:!h-[40px] max-sm:!text-lg"
        type="number"
        onPaste={handlePaste}
        min={0}
        max={9}
        placeholder="0"
        maxLength="1"
      ></input>
    </div>
  );
};

CustomCodeInput.propTypes = {};

export default CustomCodeInput;
