import React, { useState } from "react";
import CustomButton from "../../common/CustomButton";
import { Tooltip } from "react-tooltip";
import CustomInput from "../../common/CustomInput";
import { useForm } from "../../utils/useForm";
import Swal from "sweetalert2";
import Request from "../../utils/fetchUtils";
import CustomLoader from "../../common/CustomLoader";

const NuevaSolicitud = (props) => {
  const { setModalNuevaSolicitud } = props;
  const [loading, setLoading] = useState(false);

  const [userData, newDataChange, ,] = useForm({
    Tipo_de_servicio: "",
    tipo_de_industria: "",
    Descripcion_del_equipo: "",
    Valor_del_equipo: "",
    Plazo_de_interes: "",
    Comprar_activo: "",
  });

  const createNewDeal = async () => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    if (Tipo_de_servicio !== "" || tipo_de_industria !== "" || Descripcion_del_equipo !== "" || Valor_del_equipo !== ""|| Plazo_de_interes !== "" || Comprar_activo !== "") {
      const res = await Request(
        "https://f101pahekh.execute-api.us-east-2.amazonaws.com/staging/back-office/user/deal",
        {
          deal: {
            dedupId: Math.random() * (100 - 1) + 1,
            Tipo_de_servicio: Tipo_de_servicio,
            tipo_de_industria: tipo_de_industria,
            Descripcion_del_equipo: Descripcion_del_equipo,
            Valor_del_equipo: Valor_del_equipo.replace(/[^0-9.]/g, ""),
            Plazo_de_interes: Plazo_de_interes,
            Comprar_activo: Comprar_activo,
            Deal_Name: localStorage.getItem("name"),
            trigger: ["approval", "workflow", "blueprint"],
          },
        },
        "POST",
        false,
        false,
        {
          Authorization: `Bearer ${tokenJwt}`,
        }
      );
      if (res.status) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "¡Ha creado una nueva solicitud!",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => setModalNuevaSolicitud(false));
      }
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Tienes algunos campos vacíos",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const { Tipo_de_servicio, tipo_de_industria, Valor_del_equipo, Descripcion_del_equipo, Plazo_de_interes, Comprar_activo } = userData;

  return (
    <>
      <CustomLoader isLoading={loading} />
      <div className="form-twoColumns-container" style={{ height: "fit-content" }}>
        <div className="input-twoColumns-container">
          <CustomInput
            label="Tipo de servicio"
            placeholder="Selecciona una opción"
            name="Tipo_de_servicio"
            type="select"
            onChange={newDataChange}
            value={Tipo_de_servicio}
            withTooltip={true}
            tooltipText={
              "Leaseback: Compramos tus activos para capitalizar tu empresa. Los activos seguirán siendo parte de tu operación durante todo el plazo. \n \nArrendamiento: Consigue el activo nuevo que necesitas para hacer crecer tu empresa sin descapitalizarte. Además, cambia el activo por uno nuevo al final del plazo."
            }
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Arrendamiento", //Leasing
                value: "Leasing",
              },
              {
                label: "Sales & Leaseback",
                value: "Leaseback",
              },
            ]}
          ></CustomInput>
        </div>
        <div className="input-twoColumns-container">
          <CustomInput
            label="Tipo de activo"
            placeholder="Selecciona una opción"
            name="tipo_de_industria"
            type="select"
            onChange={newDataChange}
            value={tipo_de_industria}
            withTooltip={true}
            tooltipText={"Elige la categoría del activo que necesitas para entender mejor la solicitud."}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "Cómputo",
                value: "Cómputo",
              },
              {
                label: "Movilidad",
                value: "Movilidad",
              },
              {
                label: "Maquinaria",
                value: "Maquinaria",
              },
              {
                label: "Motocicletas",
                value: "Motocicletas",
              },
              {
                label: "Salud",
                value: "Salud",
              },
              {
                label: "Energía",
                value: "Energía",
              },
              {
                label: "Carritos de golf",
                value: "Carritos de golf",
              },
            ]}
          ></CustomInput>
        </div>
        <div className="input-twoColumns-container">
          <CustomInput
            label="Valor del equipo ($)"
            placeholder="Ingresa la cantidad del valor del equipo"
            name="Valor_del_equipo"
            type="money"
            value={Valor_del_equipo}
            onChange={newDataChange}
          ></CustomInput>
          <div>
            <CustomInput
              label="Descripción del equipo"
              placeholder="Describe el equipo que te interesa"
              name="Descripcion_del_equipo"
              type="textarea"
              value={Descripcion_del_equipo}
              onChange={newDataChange}
            ></CustomInput>
          </div>
        </div>
        <div className="input-twoColumns-container">
          <CustomInput
            label="¿A cuántos plazos te interesa?"
            placeholder="Selecciona una opción"
            name="Plazo_de_interes"
            type="select"
            onChange={newDataChange}
            value={Plazo_de_interes}
            options={[
              {
                label: "Seleccione una opción",
                value: "",
              },
              {
                label: "12 meses",
                value: "12 meses",
              },
              {
                label: "24 meses",
                value: "24 meses",
              },
              {
                label: "36 meses",
                value: "36 meses",
              },
              {
                label: "48 meses",
                value: "48 meses",
              },
              {
                label: "60 meses",
                value: "60 meses",
              },
            ]}
          ></CustomInput>
          <div>
            <CustomInput
              label="¿Qué te gustaría hacer al final del plazo?"
              placeholder="Selecciona una opción"
              name="Comprar_activo"
              type="select"
              onChange={newDataChange}
              value={Comprar_activo}
              withTooltip={true}
              tooltipText="No te preocupes, esta información no te compromete a comprar o remplazar el activo, es solo para fines informativos que puede darte información valiosa para tus cálculos."
              options={[
                {
                  label: "Seleccione una opción",
                  value: "",
                },
                {
                  label: "Comprar",
                  value: "Comprar",
                },
                {
                  label: "Reemplazar",
                  value: "Reemplazar",
                },
                {
                  label: "No estoy seguro",
                  value: "No estoy seguro",
                },
              ]}
            ></CustomInput>
          </div>
          <Tooltip
            id="my-tooltip"
            style={{ whiteSpace: "break-spaces", backgroundColor: "#1A222F", fontWeight: 300, fontSize: "1vw", width: "20vw", padding: "2vw" }}
            place="right"
          />
        </div>
      </div>
      <div
        className="welcome-btn-container"
        style={{
          display: "flex",
          placeContent: "center",
          width: "100%",
          justifyContent: "center",
          marginBottom: "1vw",
        }}
      >
        <CustomButton
          style={{ width: "20vw", fontWeight: 700, backgroundColor: "#f6f4ea", color: "#1a222f" }}
          text="Crear nueva solicitud"
          onClick={() => {
            createNewDeal();
          }}
        ></CustomButton>
      </div>
    </>
  );
};

export default NuevaSolicitud;
