import React from "react";
import { FileText } from "lucide-react";

const DocumentItemList = (props) => {
  const { docsList, docsStatus } = props;
  console.log(docsList)
  return (
    <div
      className="doc-view"
      style={
        docsStatus === "Pendiente"
          ? { border: "0.1vw solid #4944FC" }
          : docsStatus === "Aprobado"
          ? { border: "0.1vw solid #73dc6f" }
          : docsStatus === "Rechazado"
          ? { border: "0.1vw solid #FF3366" }
          : {}
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <FileText style={{ color: "#415575", margin: "0vw 0.3vw" }} size={16}></FileText>
        <div className="doc-view-title">{docsList.userFileName}</div>
      </div>
      {/* <MoreHorizontal style={{ color: "#415575", margin: "0vw 0.3vw" }} size={16}></MoreHorizontal> */}
    </div>
  );
};

DocumentItemList.propTypes = {};

export default DocumentItemList;
