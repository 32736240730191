import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { HelpCircle } from "lucide-react";

const InputPhoneCountry = ({
  value,
  onChange,
  withTooltip,
  label,
  tooltipText,
  classNameContainer = "",
  error = "",
  ...props
}) => {
  return (
    <>
      {label && (
        <div className="input-label">
          {label}
          {withTooltip && (
            <HelpCircle
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltipText}
              className="help-icon2"
              fill="#1A222F"
              color="white"
            />
          )}
        </div>
      )}
      <PhoneInput
        defaultCountry="mx"
        value={value}
        onChange={onChange}
        className={`input-container bg-white ${classNameContainer}`}
        inputClassName="input-item-custom !text-[1rem]"
        countrySelectorStyleProps={{
          className: "pl-2 !max-h-full",
          buttonClassName: "!border-none !max-h-full !h-full",
          flagClassName: "!h-full",
          dropdownStyleProps: {
            className: "!max-h-[150px]",
          },
        }}
      />
      {error && <div className="text-red-500 text-sm -mt-2 mb-2">{error}</div>}
    </>
  );
};

export default InputPhoneCountry;
