import React from "react";

const CustomButton2 = (props) => {
  const { text, icon, onClick, style } = props;
  return (
    <div className="btn-container2" onClick={onClick} style={style}>
      {icon}
      <div className="textBtn-container2">{text}</div>
    </div>
  );
};

CustomButton2.propTypes = {};

export default CustomButton2;
