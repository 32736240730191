import { GENERAL_INIT, GENERAL_LOGOUT, GENERAL_NOTIFICATIONS, GENERAL_SAMEFOLDER } from "../actions";

const INITIAL_STATE = {
  userData: {},
  estatusData:[],
  notifications: []
};

export const generalReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GENERAL_INIT:
      return {
        ...state,
        userData: payload,
      };
    case GENERAL_SAMEFOLDER:
      return {
        ...state,
        estatusData: payload,
      };
    case GENERAL_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case GENERAL_LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
