// Collapse.js
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";

const Collapse = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const he = require('he');
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="collapse-container">
      <div className="seccionDocumentos-header" onClick={toggleCollapse}>
        <div className="seccionDocumentos-title">{he.decode(title)}</div>
        {isCollapsed ? <ChevronDown className="colapseIcon"></ChevronDown> : <ChevronUp className="colapseIcon"></ChevronUp>}
      </div>
      {!isCollapsed && <div className="collapse-content">{children}</div>}
    </div>
  );
};

export default Collapse;
