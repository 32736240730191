import { Grid, List } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../common/CustomLoader";
import Skinpage from "../../common/Skinpage";
import SpellingCorrection from "../../common/SpellingCorrection";
import MainLayout from "../../components/layout/MainLayout";
import { fetchDataFoldersZoho } from "../../redux/actions/generalActions";
import CarruselInfo from "./CarruselInfo";
import ProgressDocBar from "./ProgressDocBar";
import SeccionDocumentos from "./SeccionDocumentos";

const Dashboard = () => {
  const [isFilterGrid, setIsFilterGrid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arreglosAgrupados, setArreglosAgrupados] = useState([]);

  const [folderSelected, setFolderSelected] = useState({});
  const [checked, setChecked] = useState(
    localStorage.getItem("avalMismoRL") === "Si" ? true : false
  );

  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.general);

  function formatearNombre(nombre) {
    const palabras = nombre.split("_");
    const nombreFormateado = palabras
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(" ");

    return nombreFormateado;
  }

  const paragraphs = [
    "Arrastra tus documentos en los siguientes modulos o pulsa en “buscar en mi pc” para buscarlos en tu ordenador. Completa los siguientes pasos para comenzar tu arrendamiento con Finerik.",
    "Puedes acelerar tu proceso de aprobación, adelántate subiendo tus documentos si ya los tienes a la mano.",
    "¿Tienes alguna duda? No dudes en enviarnos un mensaje si te has perdido.",
    "¿Ya viste los barras? 👀 estos te ayudan a medir tu progreso de una manera rápida, continua con la carga de tus documentos.",
    "Haz clic en los siguientes modulos para abrir una ventana emergente y buscar los archivos en tu ordenador. Completa los siguientes pasos para comenzar tu arrendamiento con Finerik.",
  ];

  useEffect(() => {
    dispatch(fetchDataFoldersZoho(setLoading, setArreglosAgrupados));
  }, [dispatch, userData]);

  const obtenerCantidadAprobados = (arreglo) => {
    return arreglo.filter((objeto) => objeto.Estatus === "Aprobado").length;
  };

  //  arreglosAgrupados = JSON.parse(userData).reduce((acumulador, objeto) => {
  //   const grupoExistente = acumulador.find(arr => arr[0]?.carpeta_superior === objeto.carpeta_superior);
  //   if (grupoExistente) {
  //     grupoExistente.push(objeto);
  //   } else {
  //     acumulador.push([objeto]);
  //   }
  //   return acumulador;
  // }, []);

  const TituloCargaDocumental =
    (localStorage.getItem("layout") === "Persona Fisica"
      ? "PFAE"
      : localStorage.getItem("layout")) +
    " con " +
    localStorage.getItem("tipoServicio");

  return (
    <Skinpage>
      <CustomLoader isLoading={loading} />
      <div className="dashboard-container">
        <div className="dashboard-generalInfo">
          <div className="header-generalInfo">
            <CarruselInfo paragraphs={paragraphs} />
          </div>
          <div className="titleFilters-container">
            <div className="general-title">
              Carga de documentos{" "}
              <span style={{ fontSize: "1.5vw" }}>
                - {TituloCargaDocumental}
              </span>
            </div>
            <div className="filters-container">
              <Grid
                className="filterGrid"
                style={isFilterGrid ? { color: "#4944fc" } : {}}
                onClick={() => setIsFilterGrid(true)}
              ></Grid>
              <List
                className="filterList"
                style={isFilterGrid ? {} : { color: "#4944fc" }}
                onClick={() => setIsFilterGrid(false)}
              ></List>
            </div>
          </div>
          <div className="cargaDocumentos-container">
            {arreglosAgrupados?.map((x) => (
              <SeccionDocumentos
                data={x}
                gridView={isFilterGrid}
                key={x.id}
                setFolderSelected={setFolderSelected}
                checked={checked}
                setChecked={setChecked}
              ></SeccionDocumentos>
            ))}
          </div>
        </div>
        <div className="dashboard-detailInfo">
          {/* <div
            className="limiteCredito-container"
            style={{ opacity: 0.3 }}
            data-tooltip-id="tooltip-limite-credito"
            data-tooltip-content="Aquí verás el límite de crédito que te daremos una vez aprobados tus documentos."
          >
            <div className="limiteCredito">
              <div style={{ fontSize: "1.3vw", fontWeight: 600 }}>$0.00</div>
              <div style={{ fontSize: "0.8vw" }}>Limite de Crédito</div>
            </div>
          </div>
          <Tooltip
            id="tooltip-limite-credito"
            style={{ whiteSpace: "break-spaces", backgroundColor: "#1A222F", fontWeight: 300, fontSize: "1vw", width: "20vw", padding: "2vw" }}
            place="right"
          /> */}
          <div className="barrasProgreso-container">
            <div className="barrasProgeso-title">Documentos Aprobados</div>
            {arreglosAgrupados?.map((x) => (
              <ProgressDocBar
                catName={x[0].Carpeta_superior}
                key={x[0].Carpeta_superior}
                percent={obtenerCantidadAprobados(x)}
                arrayLength={x.length}
              ></ProgressDocBar>
            ))}
          </div>
          <div className="mb-4">
            {/* <CustomButton2
              text="Generar reporte con Ekatena"
              onClick={() => setOpenModal(true)}
            ></CustomButton2> */}
          </div>
          <div
            className="detailInfo-container"
            style={
              folderSelected?.Estatus === null
                ? { backgroundColor: "#95A7C5", border: "0.3vw solid #415575" }
                : folderSelected?.Estatus === "En revisión"
                ? { backgroundColor: "#C8C7FE", border: "0.3vw solid #4944FC" }
                : folderSelected?.Estatus === "Aprobado"
                ? { backgroundColor: "#f1fbf1", border: "0.3vw solid #73dc6f" }
                : folderSelected?.Estatus === "Rechazado"
                ? { backgroundColor: "#FFFDE8", border: "0.3vw solid #E9E245" }
                : folderSelected?.Estatus === "Pendiente"
                ? { backgroundColor: "#95A7C5", border: "0.3vw solid #415575" }
                : { border: "0.3vw solid #FFFDE8" }
            }
          >
            <div>
              <div
                className="item-icon-grid"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {folderSelected?.Estatus === null && (
                  <img
                    className="iconItem"
                    src="./images/nuevoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Pendiente" && (
                  <img
                    className="iconItem"
                    src="./images/nuevoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "En revisión" && (
                  <img
                    className="iconItem"
                    src="./images/esperaIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Aprobado" && (
                  <img
                    className="iconItem"
                    src="./images/aceptadoIcon.svg"
                    alt=""
                  ></img>
                )}
                {folderSelected?.Estatus === "Rechazado" && (
                  <img
                    className="iconItem"
                    src="./images/rechazadoIcon.svg"
                    alt=""
                  ></img>
                )}
              </div>
              <div
                className={`detailTitle ${
                  folderSelected?.Estatus === "En revisión"
                    ? "revision"
                    : folderSelected?.Estatus === "Rechazado"
                    ? "rechazado"
                    : folderSelected?.Estatus === "Aprobado"
                    ? "aprobado"
                    : ""
                }`}
              >
                {folderSelected?.Estatus === "En revisión"
                  ? "Documentos en revisión"
                  : folderSelected?.Estatus === "Rechazado"
                  ? "Uno o más documentos no han sido aprobados"
                  : folderSelected?.Estatus === "Aprobado"
                  ? "Documentos aprobados"
                  : ""}
              </div>
              {folderSelected?.Estatus && (
                <div
                  style={{ fontSize: "0.9vw", color: "black" }}
                  className={`detailTitle ${
                    folderSelected?.Estatus === "En revisión"
                      ? "revision"
                      : folderSelected?.Estatus === "Rechazado"
                      ? "rechazado"
                      : folderSelected?.Estatus === "Aprobado"
                      ? "aprobado"
                      : ""
                  }`}
                >
                  {
                    <SpellingCorrection
                      texto={formatearNombre(folderSelected?.Name)}
                    ></SpellingCorrection>
                  }
                </div>
              )}

              <div className="detailText">
                {folderSelected?.Estatus === "En revisión"
                  ? "Estamos revisando tus archivos para asegurarnos de que estén listos. Te avisaremos pronto."
                  : folderSelected?.Estatus === "Rechazado"
                  ? "¡Ups! Alguno de tus documentos no fue aprobado. Por favor, revisa los detalles y asegúrate de que estén en orden."
                  : folderSelected?.Estatus === "Aprobado"
                  ? "¡Felicidades! todos tus documentos han sido aprobados."
                  : ""}
              </div>
            </div>
            {folderSelected?.Estatus && (
              <div className="detailMoreInfo">
                <img
                  className="iconItem"
                  src="./images/moreInfoIcon.svg"
                  alt=""
                  style={{ marginBottom: "0.3vw" }}
                ></img>
                {folderSelected?.Estatus === "En revisión"
                  ? "Por favor ten en cuenta que el proceso de revisión puede tardar de 3 a 5 días hábiles"
                  : folderSelected?.Estatus === "Rechazado"
                  ? "Revisa que tus documentos estén correctamente escaneados y en el orden correcto."
                  : folderSelected?.Estatus === "Aprobado"
                  ? "Recuerda que la vigencia de tus documentos aprobados es de 3 meses. ¡No olvides completar tu proceso a tiempo!"
                  : ""}
              </div>
            )}
          </div>
        </div>
      </div>
    </Skinpage>
  );
};

export default () => (
  <MainLayout>
    <Dashboard />
  </MainLayout>
);
