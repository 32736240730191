import { X } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ViewLeasing = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="leasingView-container"></div>
      <div className="leasingGeneralInfo-container">
        <div
          className="back-container"
          onClick={() => {
            navigate("/on-boarding");
          }}
        >
          <X className="backButton"></X>
        </div>
        <div className="leasingCards-container">
          <div className="leasing1-container">
            <div className="info-container">
              <div className="image-container">
                <img
                  className="iconView max-sm:hidden"
                  src="./images/leasing1.svg"
                  alt="leasing"
                ></img>
              </div>
              <div className="title-container">
                <span>
                  ¿Qué es <span className="spanLeasing">leasing</span> ?
                </span>
              </div>
              <div className="leasingInfo-text">
                El leasing es como un alquiler a largo plazo. Puedes usar cosas
                importantes para tu negocio sin tener que comprarlas de
                inmediato. Es una forma flexible de obtener lo que necesitas y
                pagar mensualmente.
              </div>
            </div>
            <div className="lateral1-container"></div>
          </div>
          <div className="leasing1-container">
            <div className="info-container">
              <div className="image-container">
                <img
                  className="iconView max-sm:hidden"
                  src="/images/leasing2.svg"
                ></img>
              </div>
              <div className="title-container">
                <span>Crecimiento</span>
              </div>
              <div className="leasingInfo-text">
                Con el leasing, puedes acceder a equipos y recursos clave para
                impulsar el crecimiento de tu empresa. Obtén lo que necesitas
                para expandirte y tener más éxito sin gastar todo tu dinero de
                una vez.
              </div>
            </div>
            <div className="lateral2-container"></div>
          </div>
          <div className="leasing1-container">
            <div className="info-container">
              <div className="image-container">
                <img
                  className="iconView max-sm:hidden"
                  src="/images/leasing3.svg"
                ></img>
              </div>
              <div className="title-container">
                <span>Financiamiento</span>
              </div>
              <div className="leasingInfo-text">
                El leasing te brinda una opción de financiamiento conveniente.
                En lugar de pagar todo de una vez, puedes distribuir los pagos
                en el tiempo. Así puedes administrar mejor tu flujo de efectivo
                y tener el capital necesario para tus operaciones.
              </div>
            </div>
            <div className="lateral3-container"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLeasing;
