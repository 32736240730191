/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Skinpage from "../../common/Skinpage";
import CustomLoader from "../../common/CustomLoader";
import CustomTable from "../../common/CustomTable";
import {
  Eye,
  Headset,
  FileUp,
  BookCheck,
  FilePen,
  FolderSync,
} from "lucide-react";
import CustomModal from "../../common/CustomModal";
import { useNavigate } from "react-router-dom";
import AgendarSesionZoho from "./AgendarSesionZoho";
import AceptarCotizacion from "./AceptarCotizacion";
import useStore from "../../zustand/useStore";
import Request, { BASE_URLS } from "../../utils/fetchUtils";
import { Tooltip } from "react-tooltip";
import NuevaSolicitud from "./NuevaSolicitud";
import MainLayout from "../../components/layout/MainLayout";

const Inicio = () => {
  const [loading, setLoading] = useState(false);
  const [modalDetalleSolicitud, setModalDetalleSolicitud] = useState(false);
  const [modalAgendarMeeting, setModalAgendarMeeting] = useState(false);
  const [modalAceptarCotizacion, setModalAceptarCotizacion] = useState(false);
  const [modalNuevaSolicitud, setModalNuevaSolicitud] = useState(false);
  const [modalFirmaContrato, setModalFirmaContrato] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [solicitudSeleccionada, setSolicitudSeleccionada] = useState([]);

  const [solicitudesPendientes, setSolicitudesPendientes] = useState([]);
  const [contratosActivos, setContratosActivos] = useState([]);
  const [contratosCerrados, setContratosCerrados] = useState([]);

  const navigate = useNavigate();
  const { setTipoMenu } = useStore();

  const headers = [
    {
      headerName: "Nombre",
      headerSize: "25%",
      field: "nombreSolicitud",
    },
    {
      headerName: "Cantidad",
      headerSize: "20%",
      field: "Valor_del_equipo",
    },
    {
      headerName: "Status",
      headerSize: "20%",
      field: "Stage",
    },
    {
      headerName: "Fecha de inicio",
      headerSize: "20%",
      field: "createdAt",
    },
  ];

  const controlDetalleSolicitud = (solicitudSeleccionada) => {
    setModalDetalleSolicitud(true);
    setSolicitudSeleccionada(solicitudSeleccionada);
  };
  const controlAceptarCotizacion = (solicitudSeleccionada) => {
    setModalAceptarCotizacion(true);
    setSolicitudSeleccionada(solicitudSeleccionada);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const transformDataSolicitudes = async () => {
    setLoading(true);
    const tokenJwt = localStorage.getItem("jwt");
    const resContact = await Request(
      BASE_URLS.GENERAL + "/staging/back-office/user/contact",
      {},
      "GET",
      false,
      false,
      {
        Authorization: `Bearer ${tokenJwt}`,
      }
    );
    if (!resContact.ok) {
      setLoading(false);
    }
    if (resContact.status) {
      localStorage.setItem(
        "Solicitudes",
        JSON.stringify(resContact.contact?.Solicitudes)
      );
    }
    const solicitudesFormateadas = resContact.contact?.Solicitudes.map(
      (solicitud) => ({
        ...solicitud,
        acciones: [
          {
            icon: <Eye></Eye>,
            accion: () => controlDetalleSolicitud(solicitud),
            nombre: "Ver Detalle",
          },
          (solicitud.Stage === "Recepcion" ||
            solicitud.Stage === "Carga de Documentos") && {
            icon: <FileUp />,
            accion: () => (
              navigate("/carga-documental"), setTipoMenu("CargaDocumental")
            ),
            nombre: "Cargar Documentos",
          },
          solicitud.Stage === "Visita pendiente" && {
            icon: <Headset />,
            accion: () => setModalAgendarMeeting(true),
            nombre: "Agendar Sesión",
          },
          (solicitud.Stage === "En proceso de análisis" ||
            solicitud.Stage === "En proceso de firma") && {
            icon: <BookCheck />,
            accion: () => controlAceptarCotizacion(solicitud),
            nombre: "Ver Cotización",
          },
          // { icon: <BookCheck  />, accion: () => controlAceptarCotizacion(solicitud), nombre: "Ver Cotización" },
          solicitud.Stage === "En proceso de firma" && {
            icon: <FilePen />,
            accion: () => setModalFirmaContrato(true),
            nombre: "Firmar Contrato",
          },
          // { icon: <FilePen  />, accion: () => setModalFirmaContrato(true), nombre: "Firmar Contrato" },
        ],
        nombreSolicitud: generarNombreSolicitud(solicitud),
        Valor_del_equipo:
          solicitud.Valor_del_equipo !== undefined &&
          formatearDinero(solicitud.Valor_del_equipo),
      })
    );
    setSolicitudes(solicitudesFormateadas);

    setSolicitudesPendientes(
      solicitudesFormateadas?.filter(
        (solicitud) =>
          solicitud.Stage === "Carga de Documentos" ||
          solicitud.Stage === "En revisión" ||
          solicitud.Stage === "Visita agendada" ||
          solicitud.Stage === "Visita pendiente" ||
          solicitud.Stage === "En proceso de análisis" ||
          solicitud.Stage === "En proceso de firma"
      )
    );
    setContratosActivos(
      solicitudesFormateadas?.filter(
        (solicitud) =>
          solicitud.Stage === "Aprobado" ||
          solicitud.Stage === "Comprado" ||
          solicitud.Stage === "Entregado"
      )
    );
    setContratosCerrados(
      solicitudesFormateadas?.filter(
        (solicitud) => solicitud.Stage === "Finalizado"
      )
    );
    setLoading(false);
  };

  const generarNombreSolicitud = (solicitud) => {
    let tipoServicio = solicitud.Tipo_de_servicio;
    let numeroSolicitud = solicitud.solicitudId.slice(12, 19);

    let nombre = "Solicitud ";
    if (tipoServicio === "Leasing") {
      nombre += "Leasing #" + numeroSolicitud;
    } else if (tipoServicio === "Leaseback") {
      nombre += "Leaseback #" + numeroSolicitud;
    } else {
      // Otro tipo de servicio
      nombre += "otro #" + numeroSolicitud;
    }

    return nombre;
  };

  const formatearDinero = (cantidad) => {
    return `$${cantidad?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  useEffect(() => {
    transformDataSolicitudes();
  }, []);

  return (
    <>
      <Skinpage>
        <CustomLoader isLoading={loading} />
        <div className="dashboard-container">
          <div
            className="dashboard-generalInfo"
            style={{ width: "100%", padding: 0, overflow: "auto" }}
          >
            <div className="header-generalInfo"></div>
            <div className="titleFilters-container">
              <div className="general-title">Solicitudes pendientes</div>
              <div style={{ display: "flex" }}>
                <div
                  className="btn-addTable"
                  onClick={() => setModalNuevaSolicitud(true)}
                >
                  + Nueva Solicitud
                </div>
                <div
                  className="btn-addTable"
                  onClick={() => transformDataSolicitudes()}
                  style={{ marginLeft: "1vw" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Actualiza el estatus de tus solicitudes."
                >
                  <FolderSync></FolderSync>
                </div>
                <Tooltip
                  id="my-tooltip"
                  style={{
                    whiteSpace: "break-spaces",
                    backgroundColor: "#1A222F",
                    fontWeight: 300,
                    fontSize: "1vw",
                    width: "20vw",
                    padding: "2vw",
                  }}
                  place="right"
                />
              </div>
            </div>
            <div className="requestsList-container">
              {solicitudesPendientes?.length === 0 && (
                <div className="requestMessageEmpty">
                  No tienes solicitudes pendientes...
                </div>
              )}
              {solicitudesPendientes?.length > 0 && (
                <CustomTable
                  headers={headers}
                  data={solicitudesPendientes}
                ></CustomTable>
              )}
            </div>
            <div className="general-title">Contratos activos</div>
            <div className="requestsList-container">
              {contratosActivos?.length === 0 && (
                <div className="requestMessageEmpty">
                  No tienes contratos de solicitudes activos...
                </div>
              )}
              {contratosActivos?.length > 0 && (
                <CustomTable
                  headers={headers}
                  data={contratosActivos}
                ></CustomTable>
              )}
            </div>
            <div className="general-title">Contratos cerrados</div>
            <div className="requestsList-container">
              {contratosCerrados?.length === 0 && (
                <div className="requestMessageEmpty">
                  No tienes contratos de solicitudes cerrados...
                </div>
              )}
              {contratosCerrados?.length > 0 && (
                <CustomTable
                  headers={headers}
                  data={contratosCerrados}
                ></CustomTable>
              )}
            </div>
          </div>
        </div>
      </Skinpage>
      <CustomModal
        open={modalDetalleSolicitud}
        setOpen={setModalDetalleSolicitud}
        title={"Detalle de Solicitud"}
        withBtnsFooter={false}
        strongTitle={true}
        extraText={
          "Puedes revisar los datos de tu solicitud y el estado en donde se encuentra."
        }
      >
        <div className="modal-container">
          <div className="detalle-container">
            <div className="detalleIzq">
              <div className="detalle-item">
                <div className="detalle-subtitle">Solicitud hecha por:</div>
                <div className="detalle-name">
                  {solicitudSeleccionada.Deal_Name}
                </div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">Cantidad Solicitada:</div>
                <div>
                  {formatearDinero(solicitudSeleccionada.Valor_del_equipo)}
                </div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">Plazo de Interés:</div>
                <div>{solicitudSeleccionada.Plazo_de_interes}</div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">
                  Descripción de la Solicitud:
                </div>
                <div>{solicitudSeleccionada.Descripcion_del_equipo}</div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">Creada el día:</div>
                <div>{formatDate(solicitudSeleccionada.createdAt)}</div>
              </div>
            </div>
            <div className="detalleDer">
              <div className="detalle-item">
                <div className="detalle-subtitle">
                  Se encuentra en la etapa:
                </div>
                <div className="detalle-name2">
                  {solicitudSeleccionada.Stage === "Recepcion"
                    ? "Recepción"
                    : solicitudSeleccionada.Stage}
                </div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">Número de Solicitud:</div>
                <div className="detalle-name2">
                  #{solicitudSeleccionada.solicitudId}
                </div>
              </div>
              <div className="detalle-item">
                <div className="detalle-subtitle">Tipo de Servicio:</div>
                <div className="detalle-name2">
                  {solicitudSeleccionada.Tipo_de_servicio === "Leasing"
                    ? "Leasing (Arrendamiento)"
                    : solicitudSeleccionada.Tipo_de_servicio}
                </div>
              </div>
            </div>
          </div>
          <div
            className="support-container"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              padding: 0,
              marginTop: "2vw",
            }}
          >
            <div
              className="btn-nuevaSolicitud"
              style={{ width: "35%" }}
              onClick={() => setModalDetalleSolicitud(false)}
            >
              Aceptar
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={modalAgendarMeeting}
        setOpen={setModalAgendarMeeting}
        title={"Agenda cita con los Ejecutivos"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Para proceder con tu Solicitud es necesario que te pongas en contácto con nosotros para darle seguimiento a tu cotización."
        }
      >
        <AgendarSesionZoho></AgendarSesionZoho>
      </CustomModal>
      <CustomModal
        open={modalAceptarCotizacion}
        setOpen={setModalAceptarCotizacion}
        title={"Acepta tu Cotización"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Te hacemos entrega de tu nueva Cotización, para proceder con el contrato de tu solicitud debes aceptarla."
        }
      >
        <AceptarCotizacion
          solicitudSeleccionada={solicitudSeleccionada}
          setModalAceptarCotizacion={setModalAceptarCotizacion}
        ></AceptarCotizacion>
      </CustomModal>
      <CustomModal
        open={modalFirmaContrato}
        setOpen={setModalFirmaContrato}
        title={"Firma tu Contrato"}
        maxWidth={"80vw"}
        maxHeight={"45vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
        extraText={
          "Necesitamos que nos hagas llegar que estas de acuerdo con las especificaciones que conforman tu contrato por medio de tu firma."
        }
      >
        <div style={{ height: "37vw" }}>
          <iframe
            src={
              "https://workdrive.zoho.com/file/7ialiecdc4548a22a4132a5f9a059b1cbbbb3"
            }
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="Document Viewer"
          />
        </div>
      </CustomModal>
      <CustomModal
        open={modalNuevaSolicitud}
        setOpen={setModalNuevaSolicitud}
        title={"Cuéntanos sobre el nuevo servicio que quieres explorar"}
        maxWidth={"72vw"}
        maxHeight={"38vw"}
        withBtnsFooter={false}
        strongTitle={true}
        withCloseTag={true}
      >
        <NuevaSolicitud
          setModalNuevaSolicitud={setModalNuevaSolicitud}
        ></NuevaSolicitud>
      </CustomModal>
    </>
  );
};

const Root = () => (
  <MainLayout>
    <Inicio />
  </MainLayout>
);

export default Root;
