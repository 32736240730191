const SpellingCorrection = ({texto}) => {
  
  const correcciones = {
    "Autorizacion": "Autorización",
    "Buro": "Buró",
    "Credito": "Crédito",
    "Situacion": "Situación",
    "Conexion": "Conexión",
    "Informacion": "Información",
    "Ultimos": "Últimos",
    "Mas":"Más",
    "Avaluo":"Avalúo",
    "Dueno":"Dueño",
    "De":"de",
    "Del":"del",
    "Al":"al",
    "Id": "ID",
    "Os":"OS",
    "La":"la",
    "Para":"para",
    "Y": "y"
  };

    const palabras = texto.split(' ');
    const fraseCorregida = palabras.map(palabra => {
      if (correcciones.hasOwnProperty(palabra)) {
        return correcciones[palabra];
      }
      return palabra;
    });
    return fraseCorregida.join(' ');
};

SpellingCorrection.propTypes = {};

export default SpellingCorrection;
