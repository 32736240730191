import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { Trash } from "lucide-react";

const fileTypes = ["JPEG", "PNG", "GIF", "PDF", "JPG"];

const CustomFileUploader = (props) => {
  const { handleChange, setUploadedFiles, uploadedFiles = [] } = props;
  
  const handleChangeModal = (files) =>{
    console.log("FILE del handle modal",files)
    const filesArray = Array.from(files);
    setUploadedFiles((prevFiles) => prevFiles.concat(filesArray));
  }

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);

    setUploadedFiles(updatedFiles);
  };

  function translateText(text) {
    // Si el texto en inglés coincide exactamente con la frase en inglés que deseas traducir, retorna la traducción en español.
    if (text === "Uploaded Successfully!  Upload another?") {
      return "¡Cargado exitosamente! ¿Subir otro?";
    } else {
      return text; // Si no hay una regla específica para este texto, devuelve el texto original.
    }
  }

  function translateUploadedMessage() {
    const uploadedMessageElements = document.querySelectorAll('.sc-fqkvVR.kFhUBM');
    console.log("ESTO DE LA TRADUCCION", document);
    uploadedMessageElements.forEach(element => {
      const englishText = element.textContent.trim();
      const translatedText = translateText(englishText); // Suponiendo que ya tienes una función translateText definida
      element.textContent = translatedText;
    });
  }

  useEffect(() =>{
    const translateUploadedMessage = () => {
      const uploadedMessageElements = document.querySelectorAll('.sc-fqkvVR.kFhUBM');
      console.log("ESTO DE LA TRADUCCION", document);
      uploadedMessageElements.forEach(element => {
        const englishText = element.textContent.trim();
        const translatedText = translateText(englishText); // Suponiendo que ya tienes una función translateText definida
        element.textContent = translatedText;
      });
    };

    // Función para manejar la carga exitosa del archivo
    const handleFileUploadSuccess = () => {
      translateUploadedMessage();
    };

    // Suscribirse al evento solo una vez al montar el componente
    document.addEventListener('fileUploaded', handleFileUploadSuccess);

    // Devolver una función de limpieza para desuscribirse del evento cuando el componente se desmonte
    return () => {
      document.removeEventListener('fileUploaded', handleFileUploadSuccess);
    };

  },[uploadedFiles])

  return (
    <div className={uploadedFiles.length > 0 ? "container-withFiles":""}>
      <FileUploader multiple={true} handleChange={handleChangeModal} name="file" types={fileTypes} label={"Arrastra y suelta uno o más archivos a esta zona o haz clic aquí para seleccionar archivos..."} />
      {uploadedFiles.length > 0 && (
        <>
        <div className="docsUploaded-container">
          <div style={{fontSize:"1.3vw", marginBottom:"1vw"}}>Archivos seleccionados:</div>
          <ul className="list-docsUploaded">
            {uploadedFiles.map((file, index) => (
              <li className="selectedFile" key={index}><span className="docName-span">{`${file.name}`}</span> <button onClick={() => handleRemoveFile(index)}><Trash></Trash></button></li>
            ))}
          </ul>
          <div className="btn-uploadFiles" onClick={() => handleChange()}>Subir Archivos</div>
        </div>
        </>
      )}
    </div>
  );
};

export default CustomFileUploader;
