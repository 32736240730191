import { AtSign, ChevronsLeft } from "lucide-react";
import React from "react";
import Swal from "sweetalert2";
import CustomButton from "../../../common/CustomButton";
import CustomInput from "../../../common/CustomInput";
import Request, { BASE_URLS } from "../../../utils/fetchUtils";
import { useForm } from "../../../utils/useForm";
import { useNavigate } from "react-router-dom";

const ViewForgotPassword = (props) => {
  const { setSection, slider, setLoading } = props;
  const navigate = useNavigate();
  const [userData, newDataChange] = useForm({
    email: "",
  });

  const forgotPassword = async () => {
    setLoading(true);
    const res = await Request(
      BASE_URLS.GENERAL + "/staging/auth/forgot-password",
      {
        email: email,
      },
      "POST"
    );
    if (res.status) {
      localStorage.setItem("emailGiven", email);
      Swal.fire({
        icon: "success",
        title: "¡Éxito!",
        text: "Se enviará un código a su correo para continuar con la recuperación de su cuenta.",
        showConfirmButton: true,
      }).then(() => setSection("VerifyCodePass"));
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Oh no, algo salió mal! Inténtalo de nuevo",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setLoading(false);
  };

  const { email } = userData;
  return (
    <div className="welcome-container" slider={slider}>
      <div className="info-container">
        <div className="header-container">
          <div
            className="back-container"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <ChevronsLeft className="backButton"></ChevronsLeft>
          </div>
          <div className="image-container">
            <img
              className="iconView"
              src="/images/forgotPass1Icon.svg"
              alt="forgot"
            ></img>
          </div>
        </div>
        <div className="title-container-register">
          <span>¿Olvidaste tu contraseña?</span>
        </div>
        <div className="info-text-container">
          ¡Oops! ¿Olvidaste tu contraseña? Te tenemos cubierto, no te preocupes,
          estamos aquí para ayudarte. Introduce tu email y recuperaremos tu
          acceso.
        </div>
        <div className="form-container-register">
          <CustomInput
            placeholder="Correo electrónico"
            name="email"
            value={email}
            onChange={newDataChange}
            icon={<AtSign></AtSign>}
          ></CustomInput>
        </div>
        <div className="welcome-btn-container">
          <CustomButton
            text="Continuar"
            onClick={() => {
              forgotPassword();
            }}
          ></CustomButton>
        </div>
      </div>
      <div className="btnAction-container">
        <div
          className="btn"
          onClick={() => {
            forgotPassword();
          }}
        >
          <img className="btnNext" src="/images/nextBtn.svg" alt="next"></img>
        </div>
      </div>
    </div>
  );
};

ViewForgotPassword.propTypes = {};

export default ViewForgotPassword;
