import React from "react";

const LogoMoffin = () => {
  return (
    <svg
      data-v-5449dad6=""
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      className="h-6 w-auto sm:h-8"
    >
      <g data-v-5449dad6="" transform="translate(2735.5 5252)">
        <path
          data-v-5449dad6=""
          d="M27.384,95.328,20.32,64.1C34,53.266,80.585,49.666,90.986,66.258L84.668,95.323S70.206,99.294,55.885,99.3a120.982,120.982,0,0,1-28.5-3.972Z"
          transform="translate(-2742.82 -5259.718)"
          className="base"
        ></path>{" "}
        <ellipse data-v-5449dad6="" cx="28.255" cy="7.381" rx="28.255" ry="7.381" transform="translate(-2715 -5165.947)" class="shadow"></ellipse>{" "}
        <path
          data-v-5449dad6=""
          d="M70.566,50.8a13.469,13.469,0,0,1-22.685,0,13.451,13.451,0,0,1-23.316,0A13.314,13.314,0,0,1,13.223,57,13.006,13.006,0,0,1,0,44.225,12.67,12.67,0,0,1,6.092,33.465,18.652,18.652,0,0,1,5,27.161C5,16.5,13.96,7.862,25.013,7.862a20.679,20.679,0,0,1,6.2.944,20.427,20.427,0,0,1,33.6,0,20.677,20.677,0,0,1,6.2-.944c11.053,0,20.013,8.64,20.013,19.3a18.651,18.651,0,0,1-1.37,7.033,12.649,12.649,0,0,1,5.79,10.568A13.006,13.006,0,0,1,82.223,57.538,13.29,13.29,0,0,1,70.566,50.8Z"
          transform="translate(-2734.5 -5251)"
          className="top"
        ></path>
      </g>
    </svg>
  );
};

export default LogoMoffin;
