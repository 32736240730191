import React from "react";
import PropTypes from "prop-types";
import LogoMoffin from "./LogoMoffin";

const CustomProgressBar = (props) => {
  const { porcent = 90, finishProgress = false, fromMoffin = false } = props;
  return (
    <>
      {!fromMoffin && (
        <div className="progressBar-container">
          <div className="line-progressBar" style={finishProgress ? { backgroundColor: "black" } : {}}>
            <div className="dot-progressBar" style={porcent > 0 ? { backgroundColor: "black" } : {}}></div>
            <div className="dot-progressBar" style={porcent > 25 ? { backgroundColor: "black" } : {}}></div>
            <div className="dot-progressBar" style={porcent > 50 ? { backgroundColor: "black" } : {}}></div>
            <div
              className="line-progressBar-inner"
              style={
                porcent > 0 && porcent <= 25
                  ? { backgroundColor: "black", width: "10%" }
                  : porcent > 25 && porcent <= 50
                  ? { backgroundColor: "black", width: "39%" }
                  : porcent > 50 && porcent <= 75
                  ? { backgroundColor: "black", width: "65%" }
                  : porcent > 75 && porcent <= 100
                  ? { display: "none" }
                  : {}
              }
            ></div>
          </div>
          <div className="porcent-progressBar">{porcent}%</div>
        </div>
      )}
      {fromMoffin && (
        <div className="progressBar-container" style={{backgroundColor:"transparent", border:"none"}}>
        <div className="line-progressBar" style={finishProgress ? { backgroundColor: "#4944FC" } : {}}>
          <div className="dot-progressBar" style={porcent > 0 ? { backgroundColor: "#4944FC" } : {}}></div>
          <div className="dot-progressBar" style={porcent > 25 ? { backgroundColor: "#4944FC" } : {}}></div>
          <div className="dot-progressBar" style={porcent >= 50 ? { backgroundColor: "#4944FC" } : {}}></div>
          <div
            className="line-progressBar-inner"
            style={
              porcent > 0 && porcent <= 25
                ? { backgroundColor: "#4944FC", width: "10%", left:"20.9%" }
                : porcent > 25 && porcent <= 50
                ? { backgroundColor: "#4944FC", width: "45%", left:"20.9%" }
                : porcent > 50 && porcent <= 75
                ? { backgroundColor: "#4944FC", width: "65%", left:"20.9%" }
                : porcent > 75 && porcent <= 100
                ? { display: "none" }
                : {}
            }
          ></div>
        </div>
        <LogoMoffin></LogoMoffin>
      </div>
      )}
    </>
  );
};

CustomProgressBar.propTypes = {};

export default CustomProgressBar;
